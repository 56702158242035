import {
  Button,
  FormControl,
  FormLabel,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  SimpleGrid,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { ALPHA_NUMERIC_SPACE_DASH_REGX } from '../../utils/constants/regex';
import { AddedVatId } from '../../utils/constants/types';
import { getPersonalFormOptions } from '../../utils/helpers';
import { validateVatIds, VatIdValidity } from '../../utils/helpers/vatChecker';
import { ToolTip } from '../ToolTip';
import { InputField } from './InputField';

interface VatIdInputViewProps {
  addressCountryCode: string | undefined;
  setVatIds: (args: AddedVatId) => void;
}

const VatIdInputView = (props: VatIdInputViewProps) => {
  const { t } = useTranslation(['liabilities']);
  const x = useTranslation(['others', 'personal'])[0];
  const { countryOptions } = getPersonalFormOptions(x);
  const colSpan = useBreakpointValue({ base: 2, md: 1 });
  const submitColSpan = useBreakpointValue({ base: 2, md: 4 });

  const [countryCode, setCountryCode] = useState(props.addressCountryCode || 'DE');
  const [vatId, setVatId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [vatIdValidationAttempted, setAttemptToValidateVatId] = useState(false);
  const [isVatIdValid, setVatIdValidity] = useState<boolean>(false);
  const [vatIdValidFrom, setVatIdValidFrom] = useState<string | null>(null);
  const [taxNumber, setTaxNumber] = useState('');

  useEffect(() => {
    if (props.addressCountryCode) setCountryCode(props.addressCountryCode);
  }, [props.addressCountryCode]);

  const updateVatIds = () => {
    const vatIdWithoutCountryCode = vatId.replace(countryCode, '');
    const vatIdWithCountryCode = `${countryCode}${vatIdWithoutCountryCode}`;

    if (vatIdValidationAttempted) {
      props.setVatIds({
        vatId: vatIdWithCountryCode,
        validity: isVatIdValid ? VatIdValidity.VALID : VatIdValidity.INVALID,
        validFrom: vatIdValidFrom,
        taxNumber,
      });
      setVatId('');
      setTaxNumber('');
      setVatIdValidFrom(null);
      setVatIdValidity(false);
      setAttemptToValidateVatId(false);
      return;
    }

    setIsLoading(true);
    validateVatIds([{ vatId: vatIdWithCountryCode }])
      .then((vatIds) => {
        if (vatId[0] && vatIds) {
          props.setVatIds({
            vatId: String(vatIds[0].vatId),
            validity: vatIds[0].validity,
            validFrom: vatIdValidFrom,
            taxNumber,
          });
        }
      })
      .catch(() => {
        props.setVatIds({
          vatId: vatIdWithCountryCode,
          validity: VatIdValidity.UNKNOWN,
          validFrom: vatIdValidFrom,
          taxNumber,
        });
      })
      .finally(() => {
        setVatId('');
        setTaxNumber('');
        setVatIdValidFrom(null);
        setVatIdValidity(false);
        setAttemptToValidateVatId(false);
        setIsLoading(false);
      });
  };

  const onVatIdInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\s/g, '').toUpperCase();

    if (/^[\w\-\s]+$/.test(value)) {
      if (value !== vatId) {
        setAttemptToValidateVatId(false);
      }

      setVatId(value);
    } else {
      setVatId('');
    }
  };

  const onTaxNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    setTaxNumber(value.replace(ALPHA_NUMERIC_SPACE_DASH_REGX, ''));
  };

  return (
    <>
      <SimpleGrid
        alignItems={'flex-end'}
        columns={submitColSpan}
        rowGap={3}
        columnGap={3}>
        <GridItem colSpan={colSpan}>
          <FormControl>
            <FormLabel>{t('liabilities:vatIdCountry.label')}</FormLabel>
            <Select
              value={countryCode}
              onChange={({ target }) => {
                if (target.type === 'select-one') {
                  const selectValue = target.selectedOptions[0].value;
                  setCountryCode(selectValue);
                }
              }}>
              {countryOptions.map(({ label, value }) => {
                return (
                  <option key={value} value={value}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem colSpan={colSpan}>
          <HStack>
            <FormControl>
              <HStack>
                <FormLabel>{t('liabilities:vatId.label')}</FormLabel>
              </HStack>
              <InputGroup>
                <InputLeftAddon>{countryCode}</InputLeftAddon>
                <Input
                  disabled={!countryCode}
                  onChange={onVatIdInputChange}
                  value={vatId}
                />
              </InputGroup>
            </FormControl>
          </HStack>
        </GridItem>
        <GridItem colSpan={colSpan}>
          <FormControl>
            <HStack>
              <FormLabel>{t('liabilities:vatIdFrom.label')}</FormLabel>
              <ToolTip content={t('liabilities:vatIdFrom.toolTip')} />
            </HStack>
            <ReactDatePicker
              disabled={!countryCode}
              dateFormat={'dd.MM.yyyy'}
              selected={vatIdValidFrom ? new Date(vatIdValidFrom) : null}
              onChange={(date) => {
                if (date) {
                  const offset = date.getTimezoneOffset();
                  date = new Date(date.getTime() - offset * 60 * 1000);
                  setVatIdValidFrom(date.toISOString());
                }
              }}
              maxDate={new Date()}
              customInput={<Input />}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={colSpan}>
          <HStack>
            <InputField
              id="taxNumber"
              label={t('liabilities:taxNumber.label')}
              tooltip={t('liabilities:taxNumber.toolTip')}
              isDisabled={!countryCode}
              onChange={onTaxNumberChange}
              value={taxNumber}
            />
          </HStack>
        </GridItem>
        {vatId && (
          <GridItem colSpan={submitColSpan}>
            <Button color={'white'} onClick={updateVatIds} isLoading={isLoading}>
              {t('liabilities:vatIdAdd.label')}
            </Button>
          </GridItem>
        )}
      </SimpleGrid>
    </>
  );
};

export { VatIdInputView };
