import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: string;
};

export type Address = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['AWSDateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  postal?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['AWSDateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  postal?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

export type BooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export type Client = {
  address?: Maybe<Address>;
  connectedSystems?: Maybe<ConnectedSystems>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['Int'];
  liabilities?: Maybe<Liabilities>;
  packageInformation?: Maybe<PackageInformation>;
  personal?: Maybe<Personal>;
  reopenedAt?: Maybe<Scalars['AWSDateTime']>;
  retroFilings?: Maybe<Array<Maybe<RetroFiling>>>;
  status?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
  vatSpecifics?: Maybe<VatSpecifics>;
};

export type ClientFilterInput = {
  createdAt?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IntFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<StringFilterInput>;
};

export type ClientScanResult = {
  items?: Maybe<Array<Maybe<Client>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Clientv2 = {
  address?: Maybe<Address>;
  connectedSystems?: Maybe<ConnectedSystems>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['Int'];
  liabilities?: Maybe<Liabilitiesv2>;
  packageInformation?: Maybe<PackageInformation>;
  personal?: Maybe<Personal>;
  reopenedAt?: Maybe<Scalars['AWSDateTime']>;
  retroFilings?: Maybe<Array<Maybe<RetroFiling>>>;
  status?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
  vatIds?: Maybe<Array<Maybe<VatId>>>;
  vatSpecifics?: Maybe<VatSpecifics>;
  metadata?: Maybe<Array<Maybe<MetadataInput>>>;
};

export type Clientv2ScanResult = {
  items?: Maybe<Array<Maybe<Clientv2>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ConnectedSystems = {
  connectedPlatforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasAmazonTransactionsIncluded?: Maybe<Scalars['Boolean']>;
  hasAmazonVCSEnabled?: Maybe<Scalars['Boolean']>;
  hasSystemsAlreadyConnected?: Maybe<Scalars['Boolean']>;
  isCSVImportEnabled?: Maybe<Scalars['Boolean']>;
  isTaxdooApiEnabled?: Maybe<Scalars['Boolean']>;
  otherPlatforms?: Maybe<Scalars['String']>;
};

export type ConnectedSystemsInput = {
  connectedPlatforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hasAmazonTransactionsIncluded?: InputMaybe<Scalars['Boolean']>;
  hasAmazonVCSEnabled?: InputMaybe<Scalars['Boolean']>;
  hasSystemsAlreadyConnected?: InputMaybe<Scalars['Boolean']>;
  isCSVImportEnabled?: InputMaybe<Scalars['Boolean']>;
  isTaxdooApiEnabled?: InputMaybe<Scalars['Boolean']>;
  otherPlatforms?: InputMaybe<Scalars['String']>;
};

export enum EntityType {
  Legal = 'LEGAL',
  Natural = 'NATURAL',
  Partnership = 'PARTNERSHIP',
  PartnershipComplementary = 'PARTNERSHIP_COMPLEMENTARY',
}

export enum FbaEnrollmentType {
  Cee = 'CEE',
  PanEu = 'PAN_EU',
}

export type IntFilterInput = {
  beginsWith?: InputMaybe<Scalars['Int']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
};

export type Liabilities = {
  FBAEnrollmentType?: Maybe<Scalars['String']>;
  firstOssPeriod?: Maybe<Scalars['String']>;
  hasAdditionalEUWarehouse?: Maybe<Scalars['Boolean']>;
  hasFBAEnrollment?: Maybe<Scalars['Boolean']>;
  hasIossEnrollment?: Maybe<Scalars['Boolean']>;
  hasOssEnrollment?: Maybe<Scalars['Boolean']>;
  hasRetroFilings?: Maybe<Scalars['Boolean']>;
  iossIDDateOfReceipt?: Maybe<Scalars['String']>;
  iossId?: Maybe<Scalars['String']>;
  ossDateOfApplication?: Maybe<Scalars['AWSDateTime']>;
  vatIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LiabilitiesInput = {
  FBAEnrollmentType?: InputMaybe<FbaEnrollmentType>;
  firstOssPeriod?: InputMaybe<Scalars['String']>;
  hasAdditionalEUWarehouse?: InputMaybe<Scalars['Boolean']>;
  hasFBAEnrollment?: InputMaybe<Scalars['Boolean']>;
  hasIossEnrollment?: InputMaybe<Scalars['Boolean']>;
  hasOssEnrollment?: InputMaybe<Scalars['Boolean']>;
  hasRetroFilings?: InputMaybe<Scalars['Boolean']>;
  iossIDDateOfReceipt?: InputMaybe<Scalars['String']>;
  iossId?: InputMaybe<Scalars['String']>;
  ossDateOfApplication?: InputMaybe<Scalars['AWSDateTime']>;
  vatIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LiabilitiesInputv2 = {
  FBAEnrollmentType?: InputMaybe<FbaEnrollmentType>;
  firstOssPeriod?: InputMaybe<Scalars['String']>;
  hasAdditionalEUWarehouse?: InputMaybe<Scalars['Boolean']>;
  hasFBAEnrollment?: InputMaybe<Scalars['Boolean']>;
  hasIossEnrollment?: InputMaybe<Scalars['Boolean']>;
  hasOssEnrollment?: InputMaybe<Scalars['Boolean']>;
  hasRetroFilings?: InputMaybe<Scalars['Boolean']>;
  iossIDDateOfReceipt?: InputMaybe<Scalars['String']>;
  iossId?: InputMaybe<Scalars['String']>;
  ossDateOfApplication?: InputMaybe<Scalars['AWSDateTime']>;
};

export type Liabilitiesv2 = {
  FBAEnrollmentType?: Maybe<Scalars['String']>;
  firstOssPeriod?: Maybe<Scalars['String']>;
  hasAdditionalEUWarehouse?: Maybe<Scalars['Boolean']>;
  hasFBAEnrollment?: Maybe<Scalars['Boolean']>;
  hasIossEnrollment?: Maybe<Scalars['Boolean']>;
  hasOssEnrollment?: Maybe<Scalars['Boolean']>;
  hasRetroFilings?: Maybe<Scalars['Boolean']>;
  iossIDDateOfReceipt?: Maybe<Scalars['String']>;
  iossId?: Maybe<Scalars['String']>;
  ossDateOfApplication?: Maybe<Scalars['AWSDateTime']>;
};

export type Metadata = {
  pageFourCompleted?: Maybe<Scalars['AWSDateTime']>;
  pageOneCompleted?: Maybe<Scalars['AWSDateTime']>;
  pageThreeCompleted?: Maybe<Scalars['AWSDateTime']>;
  pageTwoCompleted?: Maybe<Scalars['AWSDateTime']>;
  sessionCanceled?: Maybe<Scalars['AWSDateTime']>;
  sessionStarted?: Maybe<Scalars['AWSDateTime']>;
};

export type MetadataInput = {
  pageFourCompleted?: InputMaybe<Scalars['AWSDateTime']>;
  pageOneCompleted?: InputMaybe<Scalars['AWSDateTime']>;
  pageThreeCompleted?: InputMaybe<Scalars['AWSDateTime']>;
  pageTwoCompleted?: InputMaybe<Scalars['AWSDateTime']>;
  sessionCanceled?: InputMaybe<Scalars['AWSDateTime']>;
  sessionStarted?: InputMaybe<Scalars['AWSDateTime']>;
};

export type Mutation = {
  createClient?: Maybe<Client>;
  createClientv2?: Maybe<Clientv2>;
  createReferral?: Maybe<Referral>;
  createReferrer?: Maybe<Referrer>;
  deleteClientv2?: Maybe<Clientv2>;
  deleteReferral?: Maybe<Referral>;
  deleteReferrer?: Maybe<Referrer>;
  reopenSession?: Maybe<Clientv2>;
  updateClient?: Maybe<Client>;
  updateClientv2?: Maybe<Clientv2>;
  updateReferral?: Maybe<Referral>;
  updateReferrer?: Maybe<Referrer>;
};

export type MutationCreateClientArgs = {
  address?: InputMaybe<AddressInput>;
  connectedSystems?: InputMaybe<ConnectedSystemsInput>;
  id: Scalars['Int'];
  liabilities?: InputMaybe<LiabilitiesInput>;
  packageInformation?: InputMaybe<PackageInformationInput>;
  personal?: InputMaybe<PersonalInput>;
  retroFilings?: InputMaybe<Array<InputMaybe<RetroFilingInput>>>;
  status: Status;
  stripeId?: InputMaybe<Scalars['String']>;
  vatSpecifics?: InputMaybe<VatSpecificsInput>;
};

export type MutationCreateClientv2Args = {
  address?: InputMaybe<AddressInput>;
  connectedSystems?: InputMaybe<ConnectedSystemsInput>;
  id: Scalars['Int'];
  liabilities?: InputMaybe<LiabilitiesInputv2>;
  metadata?: InputMaybe<MetadataInput>;
  packageInformation?: InputMaybe<PackageInformationInput>;
  personal?: InputMaybe<PersonalInput>;
  retroFilings?: InputMaybe<Array<InputMaybe<RetroFilingInput>>>;
  status: Status;
  stripeId?: InputMaybe<Scalars['String']>;
  vatIds?: InputMaybe<Array<InputMaybe<VatIdInput>>>;
  vatSpecifics?: InputMaybe<VatSpecificsInput>;
};

export type MutationCreateReferralArgs = {
  clientId: Scalars['Int'];
  referrer: Scalars['String'];
  service: Service;
};

export type MutationCreateReferrerArgs = {
  clientId?: InputMaybe<Scalars['Int']>;
  managed: Scalars['Boolean'];
  name: Scalars['String'];
};

export type MutationDeleteClientv2Args = {
  id: Scalars['Int'];
};

export type MutationDeleteReferralArgs = {
  clientId: Scalars['Int'];
};

export type MutationDeleteReferrerArgs = {
  id: Scalars['String'];
};

export type MutationReopenSessionArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateClientArgs = {
  address?: InputMaybe<AddressInput>;
  connectedSystems?: InputMaybe<ConnectedSystemsInput>;
  id: Scalars['Int'];
  liabilities?: InputMaybe<LiabilitiesInput>;
  packageInformation?: InputMaybe<PackageInformationInput>;
  personal?: InputMaybe<PersonalInput>;
  retroFilings?: InputMaybe<Array<InputMaybe<RetroFilingInput>>>;
  status?: InputMaybe<Status>;
  stripeId?: InputMaybe<Scalars['String']>;
  vatSpecifics?: InputMaybe<VatSpecificsInput>;
};

export type MutationUpdateClientv2Args = {
  address?: InputMaybe<AddressInput>;
  connectedSystems?: InputMaybe<ConnectedSystemsInput>;
  id: Scalars['Int'];
  liabilities?: InputMaybe<LiabilitiesInputv2>;
  metadata?: InputMaybe<Array<InputMaybe<MetadataInput>>>;
  packageInformation?: InputMaybe<PackageInformationInput>;
  personal?: InputMaybe<PersonalInput>;
  retroFilings?: InputMaybe<Array<InputMaybe<RetroFilingInput>>>;
  status?: InputMaybe<Status>;
  stripeId?: InputMaybe<Scalars['String']>;
  vatIds?: InputMaybe<Array<InputMaybe<VatIdInput>>>;
  vatSpecifics?: InputMaybe<VatSpecificsInput>;
};

export type MutationUpdateReferralArgs = {
  clientId: Scalars['Int'];
  referrer?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Service>;
};

export type MutationUpdateReferrerArgs = {
  clientId?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  managed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type PackageInformation = {
  isFreeTrial?: Maybe<Scalars['Boolean']>;
};

export type PackageInformationInput = {
  isFreeTrial?: InputMaybe<Scalars['Boolean']>;
};

export type Personal = {
  entityType?: Maybe<Scalars['String']>;
  hasDataProcessingConsented?: Maybe<Scalars['Boolean']>;
  legalName?: Maybe<Scalars['String']>;
};

export type PersonalInput = {
  entityType?: InputMaybe<EntityType>;
  hasDataProcessingConsented?: InputMaybe<Scalars['Boolean']>;
  legalName?: InputMaybe<Scalars['String']>;
};

export type Query = {
  getClient?: Maybe<Client>;
  getClients?: Maybe<ClientScanResult>;
  getClientsv2?: Maybe<Clientv2ScanResult>;
  getClientv2?: Maybe<Clientv2>;
  getReferral?: Maybe<Referral>;
  getReferrals?: Maybe<ReferralScanResult>;
  getReferrer?: Maybe<Referrer>;
  getReferrers?: Maybe<ReferrerScanResult>;
  getSortedClientsByStatusv2?: Maybe<Clientv2ScanResult>;
};

export type QueryGetClientArgs = {
  id: Scalars['Int'];
};

export type QueryGetClientsArgs = {
  filter?: InputMaybe<ClientFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryGetClientsv2Args = {
  filter?: InputMaybe<ClientFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryGetClientv2Args = {
  id: Scalars['Int'];
};

export type QueryGetReferralArgs = {
  clientId: Scalars['Int'];
};

export type QueryGetReferralsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryGetReferrerArgs = {
  id: Scalars['String'];
};

export type QueryGetReferrersArgs = {
  filter?: InputMaybe<ReferrerFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryGetSortedClientsByStatusv2Args = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  status: Status;
};

export type Referral = {
  clientId: Scalars['Int'];
  createdAt: Scalars['AWSDateTime'];
  referrer: Referrer;
  service: Service;
  updatedAt: Scalars['AWSDateTime'];
};

export type ReferralScanResult = {
  items?: Maybe<Array<Maybe<Referral>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Referrer = {
  clientId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  managed: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type ReferrerFilterInput = {
  clientId?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<StringFilterInput>;
  managed?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<StringFilterInput>;
};

export type ReferrerScanResult = {
  items?: Maybe<Array<Maybe<Referrer>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type RetroFiling = {
  country?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['AWSDateTime']>;
};

export type RetroFilingInput = {
  country?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['AWSDateTime']>;
};

export enum Service {
  AutoOnboarding = 'AUTO_ONBOARDING',
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Status {
  Done = 'DONE',
  Pending = 'PENDING',
}

export type StringFilterInput = {
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
};

export type VatId = {
  taxNumber?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['AWSDateTime']>;
  vatId?: Maybe<Scalars['String']>;
};

export type VatIdInput = {
  taxNumber?: InputMaybe<Scalars['String']>;
  validFrom?: InputMaybe<Scalars['AWSDateTime']>;
  vatId?: InputMaybe<Scalars['String']>;
};

export type VatSpecifics = {
  hasDifferentialTax?: Maybe<Scalars['Boolean']>;
  hasDropshipping?: Maybe<Scalars['Boolean']>;
  hasExciseTax?: Maybe<Scalars['Boolean']>;
  hasReducedTax?: Maybe<Scalars['Boolean']>;
  hasSpecialVatTreatment?: Maybe<Scalars['Boolean']>;
  isSmallEnterprise?: Maybe<Scalars['Boolean']>;
};

export type VatSpecificsInput = {
  hasDifferentialTax?: InputMaybe<Scalars['Boolean']>;
  hasDropshipping?: InputMaybe<Scalars['Boolean']>;
  hasExciseTax?: InputMaybe<Scalars['Boolean']>;
  hasReducedTax?: InputMaybe<Scalars['Boolean']>;
  hasSpecialVatTreatment?: InputMaybe<Scalars['Boolean']>;
  isSmallEnterprise?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateClientMutationVariables = Exact<{
  id: Scalars['Int'];
  stripeId: Scalars['String'];
  status?: InputMaybe<Status>;
  address?: InputMaybe<AddressInput>;
  connectedSystems?: InputMaybe<ConnectedSystemsInput>;
  personal?: InputMaybe<PersonalInput>;
  liabilities?: InputMaybe<LiabilitiesInput>;
  vatSpecifics?: InputMaybe<VatSpecificsInput>;
  retroFilings?: InputMaybe<
    Array<InputMaybe<RetroFilingInput>> | InputMaybe<RetroFilingInput>
  >;
}>;

export type UpdateClientMutation = {
  updateClient?: {
    id: number;
    status?: string | null;
    stripeId?: string | null;
    address?: {
      dateOfBirth?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      country?: string | null;
      street?: string | null;
      city?: string | null;
      postal?: string | null;
      state?: string | null;
    } | null;
    personal?: {
      legalName?: string | null;
      entityType?: string | null;
      hasDataProcessingConsented?: boolean | null;
    } | null;
    liabilities?: {
      vatIds?: Array<string | null> | null;
      hasFBAEnrollment?: boolean | null;
      FBAEnrollmentType?: string | null;
      hasAdditionalEUWarehouse?: boolean | null;
      hasOssEnrollment?: boolean | null;
      ossDateOfApplication?: string | null;
      firstOssPeriod?: string | null;
      hasIossEnrollment?: boolean | null;
      iossId?: string | null;
      iossIDDateOfReceipt?: string | null;
      hasRetroFilings?: boolean | null;
    } | null;
    vatSpecifics?: {
      isSmallEnterprise?: boolean | null;
      hasReducedTax?: boolean | null;
      hasExciseTax?: boolean | null;
      hasDifferentialTax?: boolean | null;
      hasDropshipping?: boolean | null;
      hasSpecialVatTreatment?: boolean | null;
    } | null;
    connectedSystems?: {
      hasSystemsAlreadyConnected?: boolean | null;
      connectedPlatforms?: Array<string | null> | null;
      otherPlatforms?: string | null;
      isCSVImportEnabled?: boolean | null;
      isTaxdooApiEnabled?: boolean | null;
      hasAmazonVCSEnabled?: boolean | null;
    } | null;
    retroFilings?: Array<{
      startDate?: string | null;
      country?: string | null;
    } | null> | null;
    packageInformation?: { isFreeTrial?: boolean | null } | null;
  } | null;
};

export type UpdateClientv2MutationVariables = Exact<{
  id: Scalars['Int'];
  stripeId: Scalars['String'];
  status?: InputMaybe<Status>;
  address?: InputMaybe<AddressInput>;
  connectedSystems?: InputMaybe<ConnectedSystemsInput>;
  personal?: InputMaybe<PersonalInput>;
  liabilities?: InputMaybe<LiabilitiesInputv2>;
  vatSpecifics?: InputMaybe<VatSpecificsInput>;
  retroFilings?: InputMaybe<
    Array<InputMaybe<RetroFilingInput>> | InputMaybe<RetroFilingInput>
  >;
  vatIds?: InputMaybe<Array<InputMaybe<VatIdInput>> | InputMaybe<VatIdInput>>;
  metadata?: InputMaybe<Array<InputMaybe<MetadataInput>>>;
}>;

export type UpdateClientv2Mutation = {
  updateClientv2?: {
    id: number;
    status?: string | null;
    stripeId?: string | null;
    address?: {
      dateOfBirth?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      country?: string | null;
      street?: string | null;
      city?: string | null;
      postal?: string | null;
      state?: string | null;
    } | null;
    personal?: {
      legalName?: string | null;
      entityType?: string | null;
      hasDataProcessingConsented?: boolean | null;
    } | null;
    liabilities?: {
      hasFBAEnrollment?: boolean | null;
      FBAEnrollmentType?: string | null;
      hasAdditionalEUWarehouse?: boolean | null;
      hasOssEnrollment?: boolean | null;
      ossDateOfApplication?: string | null;
      firstOssPeriod?: string | null;
      hasIossEnrollment?: boolean | null;
      iossId?: string | null;
      iossIDDateOfReceipt?: string | null;
      hasRetroFilings?: boolean | null;
    } | null;
    vatIds?: Array<{
      validFrom?: string | null;
      vatId?: string | null;
      taxNumber?: string | null;
    } | null> | null;
    vatSpecifics?: {
      isSmallEnterprise?: boolean | null;
      hasReducedTax?: boolean | null;
      hasExciseTax?: boolean | null;
      hasDifferentialTax?: boolean | null;
      hasDropshipping?: boolean | null;
      hasSpecialVatTreatment?: boolean | null;
    } | null;
    connectedSystems?: {
      hasSystemsAlreadyConnected?: boolean | null;
      connectedPlatforms?: Array<string | null> | null;
      otherPlatforms?: string | null;
      isCSVImportEnabled?: boolean | null;
      isTaxdooApiEnabled?: boolean | null;
      hasAmazonVCSEnabled?: boolean | null;
    } | null;
    retroFilings?: Array<{
      startDate?: string | null;
      country?: string | null;
    } | null> | null;
    packageInformation?: { isFreeTrial?: boolean | null } | null;
    metadata?: Array<{
      sessionStarted: string | null;
      pageOneCompleted: string | null;
      pageTwoCompleted: string | null;
      pageThreeCompleted: string | null;
      pageFourCompleted: string | null;
      sessionCanceled: string | null;
    }>;
  } | null;
};

export type CreateReferrerMutationVariables = Exact<{
  clientId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  managed: Scalars['Boolean'];
}>;

export type CreateReferrerMutation = {
  createReferrer?: { id: string; name: string; managed: boolean } | null;
};

export type DeleteReferrerMutationVariables = Exact<{
  refId: Scalars['String'];
}>;

export type DeleteReferrerMutation = {
  deleteReferrer?: { clientId?: number | null; name: string } | null;
};

export type CreateReferralMutationVariables = Exact<{
  clientId: Scalars['Int'];
  referrerId: Scalars['String'];
  service: Service;
}>;

export type CreateReferralMutation = {
  createReferral?: {
    clientId: number;
    service: Service;
    referrer: { id: string; name: string; clientId?: number | null };
  } | null;
};

export type DeleteReferralMutationVariables = Exact<{
  clientId: Scalars['Int'];
}>;

export type DeleteReferralMutation = {
  deleteReferral?: {
    clientId: number;
    service: Service;
    referrer: { id: string; name: string; clientId?: number | null };
  } | null;
};

export type UpdateReferralMutationVariables = Exact<{
  clientId: Scalars['Int'];
  referrerId?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Service>;
}>;

export type UpdateReferralMutation = {
  updateReferral?: {
    clientId: number;
    service: Service;
    referrer: { id: string; name: string; clientId?: number | null };
  } | null;
};

export type UpdateReferrerMutationVariables = Exact<{
  clientId?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
}>;

export type UpdateReferrerMutation = { updateReferrer?: { id: string } | null };

export type GetClientQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetClientQuery = {
  getClient?: {
    id: number;
    status?: string | null;
    stripeId?: string | null;
    createdAt: string;
    updatedAt: string;
    reopenedAt?: string | null;
    address?: {
      dateOfBirth?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      country?: string | null;
      street?: string | null;
      city?: string | null;
      postal?: string | null;
      state?: string | null;
    } | null;
    personal?: {
      legalName?: string | null;
      entityType?: string | null;
      hasDataProcessingConsented?: boolean | null;
    } | null;
    liabilities?: {
      vatIds?: Array<string | null> | null;
      hasFBAEnrollment?: boolean | null;
      FBAEnrollmentType?: string | null;
      hasAdditionalEUWarehouse?: boolean | null;
      hasOssEnrollment?: boolean | null;
      ossDateOfApplication?: string | null;
      firstOssPeriod?: string | null;
      hasIossEnrollment?: boolean | null;
      iossId?: string | null;
      iossIDDateOfReceipt?: string | null;
      hasRetroFilings?: boolean | null;
    } | null;
    vatSpecifics?: {
      isSmallEnterprise?: boolean | null;
      hasReducedTax?: boolean | null;
      hasExciseTax?: boolean | null;
      hasDifferentialTax?: boolean | null;
      hasDropshipping?: boolean | null;
      hasSpecialVatTreatment?: boolean | null;
    } | null;
    connectedSystems?: {
      hasSystemsAlreadyConnected?: boolean | null;
      connectedPlatforms?: Array<string | null> | null;
      otherPlatforms?: string | null;
      isCSVImportEnabled?: boolean | null;
      isTaxdooApiEnabled?: boolean | null;
      hasAmazonVCSEnabled?: boolean | null;
    } | null;
    retroFilings?: Array<{
      startDate?: string | null;
      country?: string | null;
    } | null> | null;
    packageInformation?: { isFreeTrial?: boolean | null } | null;
  } | null;
};

export type GetClientv2QueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetClientv2Query = {
  getClientv2?: {
    id: number;
    status?: string | null;
    stripeId?: string | null;
    createdAt: string;
    updatedAt: string;
    reopenedAt?: string | null;
    address?: {
      dateOfBirth?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      country?: string | null;
      street?: string | null;
      city?: string | null;
      postal?: string | null;
      state?: string | null;
    } | null;
    personal?: {
      legalName?: string | null;
      entityType?: string | null;
      hasDataProcessingConsented?: boolean | null;
    } | null;
    liabilities?: {
      hasFBAEnrollment?: boolean | null;
      FBAEnrollmentType?: string | null;
      hasAdditionalEUWarehouse?: boolean | null;
      hasOssEnrollment?: boolean | null;
      ossDateOfApplication?: string | null;
      firstOssPeriod?: string | null;
      hasIossEnrollment?: boolean | null;
      iossId?: string | null;
      iossIDDateOfReceipt?: string | null;
      hasRetroFilings?: boolean | null;
    } | null;
    vatIds?: Array<{
      validFrom?: string | null;
      vatId?: string | null;
      taxNumber?: string | null;
    } | null> | null;
    vatSpecifics?: {
      isSmallEnterprise?: boolean | null;
      hasReducedTax?: boolean | null;
      hasExciseTax?: boolean | null;
      hasDifferentialTax?: boolean | null;
      hasDropshipping?: boolean | null;
      hasSpecialVatTreatment?: boolean | null;
    } | null;
    connectedSystems?: {
      hasSystemsAlreadyConnected?: boolean | null;
      connectedPlatforms?: Array<string | null> | null;
      otherPlatforms?: string | null;
      isCSVImportEnabled?: boolean | null;
      isTaxdooApiEnabled?: boolean | null;
      hasAmazonVCSEnabled?: boolean | null;
    } | null;
    retroFilings?: Array<{
      startDate?: string | null;
      country?: string | null;
    } | null> | null;
    packageInformation?: { isFreeTrial?: boolean | null } | null;
    metadata?: Array<{
      sessionStarted: string | null;
      pageOneCompleted: string | null;
      pageTwoCompleted: string | null;
      pageThreeCompleted: string | null;
      pageFourCompleted: string | null;
      sessionCanceled: string | null;
    }>;
  } | null;
};

export type GetReferrersQueryVariables = Exact<{ [key: string]: never }>;

export type GetReferrersQuery = {
  getReferrers?: {
    items?: Array<{
      clientId?: number | null;
      id: string;
      managed: boolean;
      label: string;
      value: string;
    } | null> | null;
  } | null;
};

export type GetReferrerQueryVariables = Exact<{
  refId: Scalars['String'];
}>;

export type GetReferrerQuery = {
  getReferrer?: {
    clientId?: number | null;
    createdAt: string;
    id: string;
    managed: boolean;
    updatedAt: string;
    label: string;
    value: string;
  } | null;
};

export type GetReferralsQueryVariables = Exact<{ [key: string]: never }>;

export type GetReferralsQuery = {
  getReferrals?: {
    items?: Array<{
      clientId: number;
      createdAt: string;
      service: Service;
      updatedAt: string;
      referrer: {
        clientId?: number | null;
        createdAt: string;
        id: string;
        managed: boolean;
        updatedAt: string;
        label: string;
        value: string;
      };
    } | null> | null;
  } | null;
};

export type GetReferralQueryVariables = Exact<{
  clientId: Scalars['Int'];
}>;

export type GetReferralQuery = {
  getReferral?: {
    clientId: number;
    createdAt: string;
    service: Service;
    updatedAt: string;
    referrer: {
      clientId?: number | null;
      createdAt: string;
      id: string;
      managed: boolean;
      updatedAt: string;
      label: string;
      value: string;
    };
  } | null;
};

export const UpdateClientDocument = gql`
  mutation updateClient(
    $id: Int!
    $stripeId: String!
    $status: Status
    $address: AddressInput
    $connectedSystems: ConnectedSystemsInput
    $personal: PersonalInput
    $liabilities: LiabilitiesInput
    $vatSpecifics: VatSpecificsInput
    $retroFilings: [RetroFilingInput]
  ) {
    updateClient(
      id: $id
      stripeId: $stripeId
      status: $status
      address: $address
      connectedSystems: $connectedSystems
      personal: $personal
      liabilities: $liabilities
      vatSpecifics: $vatSpecifics
      retroFilings: $retroFilings
    ) {
      id
      status
      address {
        dateOfBirth
        firstName
        lastName
        country
        street
        city
        postal
        state
      }
      personal {
        legalName
        entityType
        hasDataProcessingConsented
      }
      liabilities {
        vatIds
        hasFBAEnrollment
        FBAEnrollmentType
        hasAdditionalEUWarehouse
        hasOssEnrollment
        ossDateOfApplication
        firstOssPeriod
        hasIossEnrollment
        iossId
        iossIDDateOfReceipt
        hasRetroFilings
      }
      vatSpecifics {
        isSmallEnterprise
        hasReducedTax
        hasExciseTax
        hasDifferentialTax
        hasDropshipping
        hasSpecialVatTreatment
      }
      connectedSystems {
        hasSystemsAlreadyConnected
        connectedPlatforms
        otherPlatforms
        isCSVImportEnabled
        isTaxdooApiEnabled
        hasAmazonVCSEnabled
      }
      retroFilings {
        startDate
        country
      }
      packageInformation {
        isFreeTrial
      }
      stripeId
    }
  }
`;
export type UpdateClientMutationFn = Apollo.MutationFunction<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stripeId: // value for 'stripeId'
 *      status: // value for 'status'
 *      address: // value for 'address'
 *      connectedSystems: // value for 'connectedSystems'
 *      personal: // value for 'personal'
 *      liabilities: // value for 'liabilities'
 *      vatSpecifics: // value for 'vatSpecifics'
 *      retroFilings: // value for 'retroFilings'
 *   },
 * });
 */
export function useUpdateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(
    UpdateClientDocument,
    options,
  );
}
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;
export const UpdateClientv2Document = gql`
  mutation updateClientv2(
    $id: Int!
    $stripeId: String!
    $status: Status
    $address: AddressInput
    $connectedSystems: ConnectedSystemsInput
    $personal: PersonalInput
    $liabilities: LiabilitiesInputv2
    $vatSpecifics: VatSpecificsInput
    $retroFilings: [RetroFilingInput]
    $vatIds: [VatIdInput]
    $metadata: [MetadataInput]
  ) {
    updateClientv2(
      id: $id
      stripeId: $stripeId
      status: $status
      address: $address
      connectedSystems: $connectedSystems
      personal: $personal
      liabilities: $liabilities
      vatSpecifics: $vatSpecifics
      retroFilings: $retroFilings
      vatIds: $vatIds
      metadata: $metadata
    ) {
      id
      status
      address {
        dateOfBirth
        firstName
        lastName
        country
        street
        city
        postal
        state
      }
      personal {
        legalName
        entityType
        hasDataProcessingConsented
      }
      liabilities {
        hasFBAEnrollment
        FBAEnrollmentType
        hasAdditionalEUWarehouse
        hasOssEnrollment
        ossDateOfApplication
        firstOssPeriod
        hasIossEnrollment
        iossId
        iossIDDateOfReceipt
        hasRetroFilings
      }
      vatIds {
        validFrom
        vatId
        taxNumber
      }
      metadata {
        pageFourCompleted
        pageOneCompleted
        pageThreeCompleted
        pageTwoCompleted
        sessionCanceled
        sessionStarted
      }
      vatSpecifics {
        isSmallEnterprise
        hasReducedTax
        hasExciseTax
        hasDifferentialTax
        hasDropshipping
        hasSpecialVatTreatment
      }
      connectedSystems {
        hasSystemsAlreadyConnected
        connectedPlatforms
        otherPlatforms
        isCSVImportEnabled
        isTaxdooApiEnabled
        hasAmazonVCSEnabled
      }
      retroFilings {
        startDate
        country
      }
      packageInformation {
        isFreeTrial
      }
      stripeId
    }
  }
`;
export type UpdateClientv2MutationFn = Apollo.MutationFunction<
  UpdateClientv2Mutation,
  UpdateClientv2MutationVariables
>;

/**
 * __useUpdateClientv2Mutation__
 *
 * To run a mutation, you first call `useUpdateClientv2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientv2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientv2Mutation, { data, loading, error }] = useUpdateClientv2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *      stripeId: // value for 'stripeId'
 *      status: // value for 'status'
 *      address: // value for 'address'
 *      connectedSystems: // value for 'connectedSystems'
 *      personal: // value for 'personal'
 *      liabilities: // value for 'liabilities'
 *      vatSpecifics: // value for 'vatSpecifics'
 *      retroFilings: // value for 'retroFilings'
 *      vatIds: // value for 'vatIds'
 *   },
 * });
 */
export function useUpdateClientv2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientv2Mutation,
    UpdateClientv2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientv2Mutation, UpdateClientv2MutationVariables>(
    UpdateClientv2Document,
    options,
  );
}
export type UpdateClientv2MutationHookResult = ReturnType<
  typeof useUpdateClientv2Mutation
>;
export type UpdateClientv2MutationResult = Apollo.MutationResult<UpdateClientv2Mutation>;
export type UpdateClientv2MutationOptions = Apollo.BaseMutationOptions<
  UpdateClientv2Mutation,
  UpdateClientv2MutationVariables
>;
export const CreateReferrerDocument = gql`
  mutation createReferrer($clientId: Int, $name: String!, $managed: Boolean!) {
    createReferrer(clientId: $clientId, name: $name, managed: $managed) {
      id
      name
      managed
    }
  }
`;
export type CreateReferrerMutationFn = Apollo.MutationFunction<
  CreateReferrerMutation,
  CreateReferrerMutationVariables
>;

/**
 * __useCreateReferrerMutation__
 *
 * To run a mutation, you first call `useCreateReferrerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferrerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferrerMutation, { data, loading, error }] = useCreateReferrerMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      name: // value for 'name'
 *      managed: // value for 'managed'
 *   },
 * });
 */
export function useCreateReferrerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReferrerMutation,
    CreateReferrerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateReferrerMutation, CreateReferrerMutationVariables>(
    CreateReferrerDocument,
    options,
  );
}
export type CreateReferrerMutationHookResult = ReturnType<
  typeof useCreateReferrerMutation
>;
export type CreateReferrerMutationResult = Apollo.MutationResult<CreateReferrerMutation>;
export type CreateReferrerMutationOptions = Apollo.BaseMutationOptions<
  CreateReferrerMutation,
  CreateReferrerMutationVariables
>;
export const DeleteReferrerDocument = gql`
  mutation deleteReferrer($refId: String!) {
    deleteReferrer(id: $refId) {
      clientId
      name
    }
  }
`;
export type DeleteReferrerMutationFn = Apollo.MutationFunction<
  DeleteReferrerMutation,
  DeleteReferrerMutationVariables
>;

/**
 * __useDeleteReferrerMutation__
 *
 * To run a mutation, you first call `useDeleteReferrerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferrerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferrerMutation, { data, loading, error }] = useDeleteReferrerMutation({
 *   variables: {
 *      refId: // value for 'refId'
 *   },
 * });
 */
export function useDeleteReferrerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReferrerMutation,
    DeleteReferrerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteReferrerMutation, DeleteReferrerMutationVariables>(
    DeleteReferrerDocument,
    options,
  );
}
export type DeleteReferrerMutationHookResult = ReturnType<
  typeof useDeleteReferrerMutation
>;
export type DeleteReferrerMutationResult = Apollo.MutationResult<DeleteReferrerMutation>;
export type DeleteReferrerMutationOptions = Apollo.BaseMutationOptions<
  DeleteReferrerMutation,
  DeleteReferrerMutationVariables
>;
export const CreateReferralDocument = gql`
  mutation createReferral($clientId: Int!, $referrerId: String!, $service: Service!) {
    createReferral(clientId: $clientId, referrer: $referrerId, service: $service) {
      clientId
      service
      referrer {
        id
        name
        clientId
      }
    }
  }
`;
export type CreateReferralMutationFn = Apollo.MutationFunction<
  CreateReferralMutation,
  CreateReferralMutationVariables
>;

/**
 * __useCreateReferralMutation__
 *
 * To run a mutation, you first call `useCreateReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferralMutation, { data, loading, error }] = useCreateReferralMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      referrerId: // value for 'referrerId'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useCreateReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReferralMutation,
    CreateReferralMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateReferralMutation, CreateReferralMutationVariables>(
    CreateReferralDocument,
    options,
  );
}
export type CreateReferralMutationHookResult = ReturnType<
  typeof useCreateReferralMutation
>;
export type CreateReferralMutationResult = Apollo.MutationResult<CreateReferralMutation>;
export type CreateReferralMutationOptions = Apollo.BaseMutationOptions<
  CreateReferralMutation,
  CreateReferralMutationVariables
>;
export const DeleteReferralDocument = gql`
  mutation deleteReferral($clientId: Int!) {
    deleteReferral(clientId: $clientId) {
      clientId
      service
      referrer {
        id
        name
        clientId
      }
    }
  }
`;
export type DeleteReferralMutationFn = Apollo.MutationFunction<
  DeleteReferralMutation,
  DeleteReferralMutationVariables
>;

/**
 * __useDeleteReferralMutation__
 *
 * To run a mutation, you first call `useDeleteReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferralMutation, { data, loading, error }] = useDeleteReferralMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useDeleteReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReferralMutation,
    DeleteReferralMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteReferralMutation, DeleteReferralMutationVariables>(
    DeleteReferralDocument,
    options,
  );
}
export type DeleteReferralMutationHookResult = ReturnType<
  typeof useDeleteReferralMutation
>;
export type DeleteReferralMutationResult = Apollo.MutationResult<DeleteReferralMutation>;
export type DeleteReferralMutationOptions = Apollo.BaseMutationOptions<
  DeleteReferralMutation,
  DeleteReferralMutationVariables
>;
export const UpdateReferralDocument = gql`
  mutation updateReferral($clientId: Int!, $referrerId: String, $service: Service) {
    updateReferral(clientId: $clientId, referrer: $referrerId, service: $service) {
      clientId
      service
      referrer {
        id
        name
        clientId
      }
    }
  }
`;
export type UpdateReferralMutationFn = Apollo.MutationFunction<
  UpdateReferralMutation,
  UpdateReferralMutationVariables
>;

/**
 * __useUpdateReferralMutation__
 *
 * To run a mutation, you first call `useUpdateReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralMutation, { data, loading, error }] = useUpdateReferralMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      referrerId: // value for 'referrerId'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useUpdateReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReferralMutation,
    UpdateReferralMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateReferralMutation, UpdateReferralMutationVariables>(
    UpdateReferralDocument,
    options,
  );
}
export type UpdateReferralMutationHookResult = ReturnType<
  typeof useUpdateReferralMutation
>;
export type UpdateReferralMutationResult = Apollo.MutationResult<UpdateReferralMutation>;
export type UpdateReferralMutationOptions = Apollo.BaseMutationOptions<
  UpdateReferralMutation,
  UpdateReferralMutationVariables
>;
export const UpdateReferrerDocument = gql`
  mutation updateReferrer($clientId: Int, $id: String!) {
    updateReferrer(clientId: $clientId, id: $id) {
      id
    }
  }
`;
export type UpdateReferrerMutationFn = Apollo.MutationFunction<
  UpdateReferrerMutation,
  UpdateReferrerMutationVariables
>;

/**
 * __useUpdateReferrerMutation__
 *
 * To run a mutation, you first call `useUpdateReferrerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferrerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferrerMutation, { data, loading, error }] = useUpdateReferrerMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateReferrerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReferrerMutation,
    UpdateReferrerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateReferrerMutation, UpdateReferrerMutationVariables>(
    UpdateReferrerDocument,
    options,
  );
}
export type UpdateReferrerMutationHookResult = ReturnType<
  typeof useUpdateReferrerMutation
>;
export type UpdateReferrerMutationResult = Apollo.MutationResult<UpdateReferrerMutation>;
export type UpdateReferrerMutationOptions = Apollo.BaseMutationOptions<
  UpdateReferrerMutation,
  UpdateReferrerMutationVariables
>;
export const GetClientDocument = gql`
  query getClient($id: Int!) {
    getClient(id: $id) {
      id
      status
      address {
        dateOfBirth
        firstName
        lastName
        country
        street
        city
        postal
        state
      }
      personal {
        legalName
        entityType
        hasDataProcessingConsented
      }
      liabilities {
        vatIds
        hasFBAEnrollment
        FBAEnrollmentType
        hasAdditionalEUWarehouse
        hasOssEnrollment
        ossDateOfApplication
        firstOssPeriod
        hasIossEnrollment
        iossId
        iossIDDateOfReceipt
        hasRetroFilings
      }
      vatSpecifics {
        isSmallEnterprise
        hasReducedTax
        hasExciseTax
        hasDifferentialTax
        hasDropshipping
        hasSpecialVatTreatment
      }
      connectedSystems {
        hasSystemsAlreadyConnected
        connectedPlatforms
        otherPlatforms
        isCSVImportEnabled
        isTaxdooApiEnabled
        hasAmazonVCSEnabled
      }
      retroFilings {
        startDate
        country
      }
      packageInformation {
        isFreeTrial
      }
      stripeId
      createdAt
      updatedAt
      reopenedAt
    }
  }
`;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientQuery(
  baseOptions: Apollo.QueryHookOptions<GetClientQuery, GetClientQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    options,
  );
}
export function useGetClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetClientQuery, GetClientQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    options,
  );
}
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<typeof useGetClientLazyQuery>;
export type GetClientQueryResult = Apollo.QueryResult<
  GetClientQuery,
  GetClientQueryVariables
>;
export const GetClientv2Document = gql`
  query getClientv2($id: Int!) {
    getClientv2(id: $id) {
      id
      status
      address {
        dateOfBirth
        firstName
        lastName
        country
        street
        city
        postal
        state
      }
      personal {
        legalName
        entityType
        hasDataProcessingConsented
      }
      liabilities {
        hasFBAEnrollment
        FBAEnrollmentType
        hasAdditionalEUWarehouse
        hasOssEnrollment
        ossDateOfApplication
        firstOssPeriod
        hasIossEnrollment
        iossId
        iossIDDateOfReceipt
        hasRetroFilings
      }
      vatIds {
        validFrom
        vatId
        taxNumber
      }
      vatSpecifics {
        isSmallEnterprise
        hasReducedTax
        hasExciseTax
        hasDifferentialTax
        hasDropshipping
        hasSpecialVatTreatment
      }
      connectedSystems {
        hasSystemsAlreadyConnected
        connectedPlatforms
        otherPlatforms
        isCSVImportEnabled
        isTaxdooApiEnabled
        hasAmazonVCSEnabled
      }
      retroFilings {
        startDate
        country
      }
      packageInformation {
        isFreeTrial
      }
      stripeId
      createdAt
      updatedAt
      reopenedAt
      metadata {
        pageFourCompleted
        pageOneCompleted
        pageThreeCompleted
        pageTwoCompleted
        sessionCanceled
        sessionStarted
      }
    }
  }
`;

/**
 * __useGetClientv2Query__
 *
 * To run a query within a React component, call `useGetClientv2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetClientv2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientv2Query({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientv2Query(
  baseOptions: Apollo.QueryHookOptions<GetClientv2Query, GetClientv2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientv2Query, GetClientv2QueryVariables>(
    GetClientv2Document,
    options,
  );
}
export function useGetClientv2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetClientv2Query, GetClientv2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientv2Query, GetClientv2QueryVariables>(
    GetClientv2Document,
    options,
  );
}
export type GetClientv2QueryHookResult = ReturnType<typeof useGetClientv2Query>;
export type GetClientv2LazyQueryHookResult = ReturnType<typeof useGetClientv2LazyQuery>;
export type GetClientv2QueryResult = Apollo.QueryResult<
  GetClientv2Query,
  GetClientv2QueryVariables
>;
export const GetReferrersDocument = gql`
  query getReferrers {
    getReferrers {
      items {
        clientId
        id
        label: name
        value: name
        managed
      }
    }
  }
`;

/**
 * __useGetReferrersQuery__
 *
 * To run a query within a React component, call `useGetReferrersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferrersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferrersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferrersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReferrersQuery, GetReferrersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReferrersQuery, GetReferrersQueryVariables>(
    GetReferrersDocument,
    options,
  );
}
export function useGetReferrersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReferrersQuery,
    GetReferrersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReferrersQuery, GetReferrersQueryVariables>(
    GetReferrersDocument,
    options,
  );
}
export type GetReferrersQueryHookResult = ReturnType<typeof useGetReferrersQuery>;
export type GetReferrersLazyQueryHookResult = ReturnType<typeof useGetReferrersLazyQuery>;
export type GetReferrersQueryResult = Apollo.QueryResult<
  GetReferrersQuery,
  GetReferrersQueryVariables
>;
export const GetReferrerDocument = gql`
  query getReferrer($refId: String!) {
    getReferrer(id: $refId) {
      clientId
      createdAt
      id
      managed
      label: name
      value: name
      updatedAt
    }
  }
`;

/**
 * __useGetReferrerQuery__
 *
 * To run a query within a React component, call `useGetReferrerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferrerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferrerQuery({
 *   variables: {
 *      refId: // value for 'refId'
 *   },
 * });
 */
export function useGetReferrerQuery(
  baseOptions: Apollo.QueryHookOptions<GetReferrerQuery, GetReferrerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReferrerQuery, GetReferrerQueryVariables>(
    GetReferrerDocument,
    options,
  );
}
export function useGetReferrerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReferrerQuery, GetReferrerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReferrerQuery, GetReferrerQueryVariables>(
    GetReferrerDocument,
    options,
  );
}
export type GetReferrerQueryHookResult = ReturnType<typeof useGetReferrerQuery>;
export type GetReferrerLazyQueryHookResult = ReturnType<typeof useGetReferrerLazyQuery>;
export type GetReferrerQueryResult = Apollo.QueryResult<
  GetReferrerQuery,
  GetReferrerQueryVariables
>;
export const GetReferralsDocument = gql`
  query getReferrals {
    getReferrals {
      items {
        clientId
        createdAt
        referrer {
          clientId
          createdAt
          id
          managed
          label: name
          value: name
          updatedAt
        }
        service
        updatedAt
      }
    }
  }
`;

/**
 * __useGetReferralsQuery__
 *
 * To run a query within a React component, call `useGetReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferralsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReferralsQuery, GetReferralsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReferralsQuery, GetReferralsQueryVariables>(
    GetReferralsDocument,
    options,
  );
}
export function useGetReferralsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReferralsQuery,
    GetReferralsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReferralsQuery, GetReferralsQueryVariables>(
    GetReferralsDocument,
    options,
  );
}
export type GetReferralsQueryHookResult = ReturnType<typeof useGetReferralsQuery>;
export type GetReferralsLazyQueryHookResult = ReturnType<typeof useGetReferralsLazyQuery>;
export type GetReferralsQueryResult = Apollo.QueryResult<
  GetReferralsQuery,
  GetReferralsQueryVariables
>;
export const GetReferralDocument = gql`
  query getReferral($clientId: Int!) {
    getReferral(clientId: $clientId) {
      clientId
      createdAt
      referrer {
        clientId
        createdAt
        id
        managed
        label: name
        value: name
        updatedAt
      }
      service
      updatedAt
    }
  }
`;

/**
 * __useGetReferralQuery__
 *
 * To run a query within a React component, call `useGetReferralQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetReferralQuery(
  baseOptions: Apollo.QueryHookOptions<GetReferralQuery, GetReferralQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReferralQuery, GetReferralQueryVariables>(
    GetReferralDocument,
    options,
  );
}
export function useGetReferralLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReferralQuery, GetReferralQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReferralQuery, GetReferralQueryVariables>(
    GetReferralDocument,
    options,
  );
}
export type GetReferralQueryHookResult = ReturnType<typeof useGetReferralQuery>;
export type GetReferralLazyQueryHookResult = ReturnType<typeof useGetReferralLazyQuery>;
export type GetReferralQueryResult = Apollo.QueryResult<
  GetReferralQuery,
  GetReferralQueryVariables
>;
