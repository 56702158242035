import { VatIdInput } from '../../graphql/generated/graphql';
import { AddedVatId } from '../constants/types';

export enum VatIdValidity {
  VALID = 'VALID',
  INVALID = 'INVALID',
  UNKNOWN = 'UNKNOWN',
}

export interface VatIdValidityResult {
  from: string | null;
  to: string | null;
  valid: string;
}

export interface VATCheckerResult {
  vatIds: {
    [key: string]: {
      externalValidities: string[];
      history: History[];
      validities: VatIdValidityResult[];
    };
  };
}

// Method finds out the relevant time ranges where the VAT ID is considered to be valid
const getValidityFromResult = (
  res: VATCheckerResult,
  vatId: string,
): false | VatIdValidityResult | undefined => {
  /**
   * The vatIds['vat-id'].validities array contains time ranges where the VAT ID is considered to be valid
   * an entry (time range) contain the properties From , To  and valid
   * valid field is always set to one of [valid, invalid, STRUCTURALLY_INVALID]
   * 'from' and 'to' contain the time ranges
   * null means Vat-Id is valid since/till that time range
   * for example {from: null, to: null}  means the VAT ID is valid at any given time valid
   */
  const { validities = [] } = res.vatIds[vatId];
  if (validities.length > 0) {
    const relevantValidity =
      validities.length > 0 &&
      validities
        .filter((item) => item.valid.toUpperCase() === VatIdValidity.VALID)
        .find(
          (item) =>
            (item.from === null && item.to === null) ||
            (item.from === null && item.to && new Date(item.to) > new Date()) ||
            (item.from && new Date(item.from) <= new Date() && item.to == null) ||
            (item.from &&
              new Date(item.from) <= new Date() &&
              item.to &&
              new Date(item.to) > new Date()),
        );

    return relevantValidity;
  }
};

// Prepare BulkCheckResult[] response data
export const parseCheckResult = (result: VATCheckerResult) => {
  const { vatIds } = result;

  return Object.entries(vatIds).map(
    // vatIdResult holds history and validity time ranges
    ([vatId, vatIdResult]) => {
      // find out the currently valid time range
      const parsedResult = getValidityFromResult(result, vatId);
      const vatIdWithStatus = {
        vatId,
        validity: VatIdValidity.INVALID,
      };

      if (parsedResult && parsedResult.valid.toUpperCase() === VatIdValidity.VALID) {
        vatIdWithStatus.validity = VatIdValidity.VALID;
      }

      return vatIdWithStatus;
    },
  );
};

export async function validateVatIds(vatIds: VatIdInput[]) {
  const vatIdsToValidate = vatIds.map(({ vatId }) => ({
    vatId,
  }));

  const requestPayload = {
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': import.meta.env.VITE_VAT_API_KEY as string,
    },
    method: 'POST',
    body: JSON.stringify({
      vatIds: vatIdsToValidate,
    }),
  };

  try {
    const response = await fetch('/vat-ids', requestPayload);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const result: VATCheckerResult = await response.json();

    return parseCheckResult(result);
  } catch (error) {
    console.log('Error in validateVatIds:', error);
    throw new Error('Error validateVatIds');
  }
}
