import { Button } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Alert } from '../components/alerts';
import { RadioSelect } from '../components/forms';
import { FormInfo } from '../components/forms/FormInfo';
import CustomerSupport from '../components/supportForm/SupportModal';
import { VatSpecifics } from '../graphql/generated/graphql';
import { OnboardingFormState } from '../utils/constants/types';

const schema = yup.object().shape({
  isSmallEnterprise: yup.boolean().required(),
  hasReducedTax: yup.boolean().required(),
  hasExciseTax: yup.boolean().required(),
  hasDifferentialTax: yup.boolean().required(),
  hasDropshipping: yup.boolean().required(),
  hasSpecialVatTreatment: yup.boolean().required(),
});

type Props = {
  state: VatSpecifics;
  isFreeTrial: boolean;
  updateState: (type: keyof OnboardingFormState, data: VatSpecifics) => void;
};

// Vat Specifics data collection form
export const VatSpecificsForm = ({
  state,
  updateState,
  isFreeTrial,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['vatSpecifics', 'others']);

  const options = t('others:yesNoOptions', {
    returnObjects: true,
  }).map((option) => ({
    label: option.label,
    value: String(option.value),
  }));

  // Initialize RHF with current progress of the personal data form
  const methods = useForm<VatSpecifics>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...state,
    },
  });

  const {
    handleSubmit,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = methods;

  const hasProductsWithReducedTax = watch('hasReducedTax');
  const hasProductsWithExciseTax = watch('hasExciseTax');
  const hasProductsWithDifferentialTax = watch('hasDifferentialTax');
  const hasDropshipping = watch('hasDropshipping');
  const specialVatTreatmentNeeded = watch('hasSpecialVatTreatment');

  // handling back button
  const onBackButton = () => {
    clearErrors();
    navigate('/onboarding/vat_data_liabilities');
  };

  // Click handler for the next button
  const onSubmit = () => {
    const routePath = '/onboarding/connected_systems';

    return async (data: VatSpecifics) => {
      setIsLoading(true);

      try {
        await updateState('vatSpecificsForm', data);
        navigate(routePath);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
  };

  useEffect(() => {
    reset(state);
  }, [state, reset]);

  return (
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <FormInfo
        header={t('vatSpecifics:title')}
        description={t('vatSpecifics:sidebarDescription')}
      />
      <div className="mt-5 md:mt-0 md:col-span-2">
        <FormProvider {...methods}>
          <form>
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6 md:p-0">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <RadioSelect
                    id="isSmallEnterprise"
                    name="isSmallEnterprise"
                    error={errors.isSmallEnterprise}
                    label={t('vatSpecifics:isSmallEnterprise.label')}
                    tooltip={t('vatSpecifics:isSmallEnterprise.toolTip')}
                    options={options}
                  />
                </div>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <RadioSelect
                    id="hasReducedTax"
                    name="hasReducedTax"
                    error={errors.hasReducedTax}
                    label={t('vatSpecifics:hasReducedTax.label')}
                    tooltip={t('vatSpecifics:hasReducedTax.toolTip')}
                    options={options}
                  />
                  {hasProductsWithReducedTax && (
                    <div className="mt-4">
                      <Alert
                        alertType="warning"
                        header={t('vatSpecifics:hasReducedTax.alertHeader')}
                        message={t('vatSpecifics:hasReducedTax.alert')}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <RadioSelect
                    id="hasExciseTax"
                    name="hasExciseTax"
                    error={errors.hasExciseTax}
                    label={t('vatSpecifics:hasExciseTax.label')}
                    tooltip={t('vatSpecifics:hasExciseTax.toolTip')}
                    options={options}
                  />
                  {hasProductsWithExciseTax && (
                    <div className="mt-4">
                      <Alert
                        alertType="warning"
                        header={t('vatSpecifics:hasExciseTax.alertHeader')}
                        message={t('vatSpecifics:hasExciseTax.alert')}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <RadioSelect
                    id="hasDifferentialTax"
                    name="hasDifferentialTax"
                    error={errors.hasDifferentialTax}
                    label={t('vatSpecifics:hasDifferentialTax.label')}
                    tooltip={t('vatSpecifics:hasDifferentialTax.toolTip')}
                    options={options}
                  />
                  {hasProductsWithDifferentialTax && (
                    <div className="mt-4">
                      <Alert
                        alertType="warning"
                        header={t('vatSpecifics:hasDifferentialTax.alertHeader')}
                        message={t('vatSpecifics:hasDifferentialTax.alert')}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <RadioSelect
                    id="hasDropshipping"
                    name="hasDropshipping"
                    error={errors.hasDropshipping}
                    label={t('vatSpecifics:hasDropshipping.label')}
                    tooltip={t('vatSpecifics:hasDropshipping.toolTip')}
                    options={options}
                  />
                  {hasDropshipping && (
                    <div className="mt-4">
                      <Alert
                        alertType="warning"
                        header={t('vatSpecifics:hasDropshipping.alertHeader')}
                        message={t('vatSpecifics:hasDropshipping.alert')}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <RadioSelect
                    id="hasSpecialVatTreatment"
                    name="hasSpecialVatTreatment"
                    error={errors.hasSpecialVatTreatment}
                    label={t('vatSpecifics:hasSpecialVatTreatment.label')}
                    options={options}
                  />
                  {specialVatTreatmentNeeded && (
                    <div className="mt-4">
                      <Alert
                        alertType="warning"
                        header={t('vatSpecifics:hasSpecialVatTreatment.alertHeader')}
                        message={t('vatSpecifics:hasSpecialVatTreatment.alert')}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-8 flex flex-wrap items-center justify-between">
              <CustomerSupport isFreeTrial={isFreeTrial}></CustomerSupport>
              <div className="mt-8 py-3 text-right sm:px-3 md:px-0 flex justify-end">
                <Button variant="outline" onClick={onBackButton}>
                  {t('others:button.back.label')}
                </Button>
                <Button
                  color={'white'}
                  ml={2}
                  onClick={handleSubmit(onSubmit())}
                  isLoading={isLoading}>
                  {t('others:button.next.label')}
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
