import { Flex, Link, LinkBox } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Footer = (): JSX.Element => {
  const { t } = useTranslation(['others']);

  return (
    <Flex
      justifyContent="center"
      paddingY="6"
      marginTop="auto"
      borderTop="1px"
      borderColor="grey.100"
      paddingX="6"
      background="white">
      <Link
        marginX="6"
        textDecoration="underline"
        href={t('others:footer.privacy.link')}
        target="_blank">
        <LinkBox>{t('others:footer.privacy.label')}</LinkBox>
      </Link>
      <Link
        marginX="6"
        textDecoration="underline"
        href={t('others:footer.gdpr.link')}
        target="_blank">
        <LinkBox>{t('others:footer.gdpr.label')}</LinkBox>
      </Link>
      <Link
        marginX="6"
        textDecoration="underline"
        href={t('others:footer.imprint.link')}
        target="_blank">
        <LinkBox>{t('others:footer.imprint.label')}</LinkBox>
      </Link>
    </Flex>
  );
};
