import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from '../../../../public/locales/de/translation.json';
import en from '../../../../public/locales/en/translation.json';

type NS = keyof typeof en;

export const defaultNS: NS = 'home';
export const resources = {
  en,
  de,
};

i18n
  // detect user language https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    ns: [...Object.keys(en)],
    supportedLngs: ['en', 'de'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    defaultNS,
  });

export default i18n;
