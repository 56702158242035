import * as yup from 'yup';

import {
  ALPHA_NUMERIC_POINT_DASH_REGX,
  ALPHA_POINT_DASH_REGX,
  COMPANY_NAME_REGX,
} from './regex';
import { OnboardingFormState } from './types';

export const INITIAL_STATE: OnboardingFormState = {
  personalForm: {
    personal: {
      legalName: '',
      entityType: '',
      hasDataProcessingConsented: false,
    },
    address: {
      firstName: '',
      lastName: '',
      street: '',
      city: '',
      state: '',
      postal: '',
      country: '',
    },
  },
  liabilitiesForm: {
    liabilities: {
      vatIds: [],
      hasFBAEnrollment: undefined,
      hasAdditionalEUWarehouse: undefined,
      hasOssEnrollment: undefined,
      hasIossEnrollment: undefined,
      hasRetroFilings: undefined,
    },
    retroFilings: [],
  },
  connectedSystemsForm: {
    hasAmazonTransactionsIncluded: undefined,
    hasSystemsAlreadyConnected: undefined,
    otherPlatforms: undefined,
    isCSVImportEnabled: undefined,
    isTaxdooApiEnabled: undefined,
    hasAmazonVCSEnabled: undefined,
  },
  vatSpecificsForm: {
    hasReducedTax: undefined,
    hasDropshipping: undefined,
    hasDifferentialTax: undefined,
    hasExciseTax: undefined,
    hasSpecialVatTreatment: undefined,
  },
};

// Date needs to be in ISO 8601 format. Otherwise the Date() will take the first number from the string as the month
export const MIN_DATE_FOR_IOSS = '2021-01-01';
export const MIN_DATE_FOR_OSS = '2021-07-01';
/**
 * @deprecated link to help-desk instead
 */
export const supportEmail = 'support@taxdoo.com';
export const salesEmail = 'sales@taxdoo.com';
export const supportRequestURL = 'https://support.taxdoo.com/hc/en-us/requests/new';
export const OTHER_INTERFACE = 'otherInterface';
export const getPersonalSchema = (
  isFreeTrial: boolean,
  countryOptions: { label: string; value: string }[],
) => {
  if (isFreeTrial) {
    return yup.object({
      personal: yup.object({
        hasDataProcessingConsented: yup.boolean().oneOf([true]),
        legalName: yup
          .string()
          .label('Company name')
          .required()
          .matches(COMPANY_NAME_REGX, 'Please enter valid company name'),
        entityType: yup.string().required().label('Entity type'),
      }),
      address: yup.object({
        firstName: yup
          .string()
          .required()
          .label('First name')
          .matches(ALPHA_POINT_DASH_REGX, 'Please enter valid name'),
        lastName: yup
          .string()
          .required()
          .label('Last name')
          .matches(ALPHA_POINT_DASH_REGX, 'Please enter valid name'),
        street: yup
          .string()
          .required()
          .label('Street')
          .matches(ALPHA_NUMERIC_POINT_DASH_REGX, 'Please enter valid street'),
        city: yup
          .string()
          .required()
          .label('city')
          .matches(ALPHA_NUMERIC_POINT_DASH_REGX, 'Please enter valid city'),
        state: yup
          .string()
          .required()
          .label('state')
          .matches(ALPHA_NUMERIC_POINT_DASH_REGX, 'Please enter valid state'),
        postal: yup
          .string()
          .required()
          .label('area')
          .matches(ALPHA_NUMERIC_POINT_DASH_REGX, 'Please enter valid postal'),
        country: yup
          .string()
          .required()
          .label('country')
          .oneOf(
            countryOptions.map((country) => country.value),
            'Please enter valid country',
          ),
      }),
    });
  }

  return yup.object({
    personal: yup.object({
      hasDataProcessingConsented: yup.boolean().oneOf([true]),
      legalName: yup
        .string()
        .label('Company name')
        .required()
        .matches(COMPANY_NAME_REGX, 'Please enter valid company name'),
      entityType: yup.string().required().label('Entity type'),
    }),
    address: yup.object({
      firstName: yup
        .string()
        .nullable()
        .label('First name')
        .matches(ALPHA_POINT_DASH_REGX, 'Please enter valid name'),
      lastName: yup
        .string()
        .nullable()
        .label('Last name')
        .matches(ALPHA_POINT_DASH_REGX, 'Please enter valid name'),
      street: yup
        .string()
        .nullable()
        .label('Street')
        .matches(ALPHA_NUMERIC_POINT_DASH_REGX, 'Please enter valid street'),
      city: yup
        .string()
        .nullable()
        .label('city')
        .matches(ALPHA_NUMERIC_POINT_DASH_REGX, 'Please enter valid city'),
      state: yup
        .string()
        .nullable()
        .label('state')
        .matches(ALPHA_NUMERIC_POINT_DASH_REGX, 'Please enter valid state'),
      postal: yup
        .string()
        .nullable()
        .label('area')
        .matches(ALPHA_NUMERIC_POINT_DASH_REGX, 'Please enter valid postal'),
      country: yup
        .string()
        .required()
        .label('country')
        .oneOf(
          countryOptions.map((country) => country.value),
          'Please enter valid country',
        ),
    }),
  });
};

export enum API_NAMES {
  AO_API = 'aoApi',
  REFERRAL_API = 'referralApi',
  SERVICE_API = 'serviceApi',
}

export const SUCCESS_PAGE_PATH = '/onboarding/end';
