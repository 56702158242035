import { FormControl, FormErrorMessage, FormLabel, HStack, Text } from '@chakra-ui/react';
import React from 'react';

import { FieldProps } from '../../utils/constants/types';
import { ToolTip } from '../ToolTip';

type Props = FieldProps & {
  children: React.ReactNode;
};

export const FormField = ({
  id,
  label,
  error,
  error_msg,
  tooltip,
  children,
}: Props): JSX.Element => (
  <FormControl isInvalid={!!error}>
    <FormLabel htmlFor={id}>
      <HStack>
        <Text>{label}</Text> {tooltip && <ToolTip content={tooltip} />}
      </HStack>
    </FormLabel>
    {children}
    {error && (
      <FormErrorMessage>{error_msg ? error_msg : 'Field is required'}</FormErrorMessage>
    )}
  </FormControl>
);

export default FormField;
