import {
  CheckCircleIcon,
  DeleteIcon,
  NotAllowedIcon,
  QuestionOutlineIcon,
} from '@chakra-ui/icons';
import { IconButton, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { t } from 'i18next';
import React from 'react';

import { AddedVatId, euCountryType } from '../../utils/constants/types';
import { VatIdValidity } from '../../utils/helpers/vatChecker';

type Props = {
  vatIds?: AddedVatId[];
  euCountries: Record<euCountryType, string>;
  setVatIds: (vatIds: AddedVatId[]) => void;
};

export const ShowVatIds = ({ vatIds, euCountries, setVatIds }: Props) => {
  if (!vatIds || vatIds.length === 0) {
    return null;
  }

  return (
    <div className="col-span-6 sm:col-span-6">
      {/* Display added vat ids here */}
      <Table size="sm" mb={5} colorScheme="facebook">
        <Thead>
          <Tr>
            <Th w={'20%'}>{t<string>('liabilities:vatIdCountry.label')}</Th>
            <Th w={'20%'}>{t<string>('liabilities:vatId.label')}</Th>
            <Th w={'20%'}>{t<string>('liabilities:taxNumber.label')}</Th>
            <Th w={'20%'}>{t<string>('liabilities:vatIdFrom.label')}</Th>
            <Th w={'15%'} textAlign="center">
              {t<string>('liabilities:vatIdValidity.label')}
            </Th>
            <Th w={'5%'}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {vatIds.map(({ vatId, validFrom, validity, taxNumber }) => {
            const vatIdCountryCode = (vatId as string).substring(0, 2);
            let validFromDate = validFrom ? new Date(validFrom) : null;

            if (validFromDate) {
              const offset = validFromDate.getTimezoneOffset();
              validFromDate = new Date(validFromDate.getTime() - offset * 60 * 1000);
            }

            const formattedDate = validFromDate
              ? `${
                  validFromDate?.getDate() < 10
                    ? `0${validFromDate?.getDate()}`
                    : validFromDate?.getDate()
                }.${
                  validFromDate?.getMonth() + 1 < 10
                    ? `0${validFromDate?.getMonth() + 1}`
                    : validFromDate?.getMonth() + 1
                }.${validFromDate?.getFullYear()}`
              : '';
            return (
              <Tr
                key={vatId}
                color={validity === VatIdValidity.INVALID ? 'red.400' : 'grey.400'}>
                <Td>{euCountries[vatIdCountryCode as euCountryType]}</Td>
                <Td>{vatId}</Td>
                <Td>{taxNumber}</Td>
                <Td>{formattedDate}</Td>
                <Td textAlign="center">
                  {validity === VatIdValidity.VALID && (
                    <CheckCircleIcon color="green" fontSize={'2xl'} />
                  )}
                  {validity === VatIdValidity.INVALID && (
                    <NotAllowedIcon fontSize={'2xl'} />
                  )}
                  {(validity === VatIdValidity.UNKNOWN || !validity) && (
                    <QuestionOutlineIcon color="grey" fontSize={'2xl'} />
                  )}
                </Td>
                <Td>
                  <IconButton
                    aria-label="Delete VatID"
                    variant={'ghost'}
                    size="lg"
                    colorScheme={'grey'}
                    icon={<DeleteIcon />}
                    onClick={() => {
                      const newVatIds = vatIds.filter((vat) => vat.vatId !== vatId);
                      setVatIds(newVatIds);
                    }}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
};
