import {
  Button,
  GridItem,
  HStack,
  Icon,
  IconButton,
  SimpleGrid,
  useBreakpointValue,
} from '@chakra-ui/react';
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Alert } from '../components/alerts';
import { DatePicker, InputField, RadioSelect, Select } from '../components/forms';
import { FormInfo } from '../components/forms/FormInfo';
import { ShowVatIds } from '../components/forms/ShowVatIds';
import { VatIdInputView } from '../components/forms/VatIdInputView';
import CustomerSupport from '../components/supportForm/SupportModal';
import { MIN_DATE_FOR_IOSS, MIN_DATE_FOR_OSS } from '../utils/constants';
import {
  AddedVatId,
  LiabilitiesFormState,
  OnboardingFormState,
  RetroFilingFieldError,
} from '../utils/constants/types';
import { getPersonalFormOptions } from '../utils/helpers';
import { validateVatIds, VatIdValidity } from '../utils/helpers/vatChecker';
import { validateIossId } from '../utils/helpers/verifyIossId';

const schema = yup.object().shape({
  liabilities: yup.object().shape({
    hasRetroFilings: yup.boolean().nullable(true),
    hasOssEnrollment: yup.boolean().nullable(true),
    hasIossEnrollment: yup.boolean().nullable(true),
    ossDateOfApplication: yup
      .string()
      .when('hasOssEnrollment', (hasOssEnrollment: boolean) => {
        return hasOssEnrollment
          ? yup.string().required()
          : yup.string().notRequired().nullable(true);
      }),
    iossId: yup
      .string()
      .test('IossID', (id, { parent }) => {
        return parent.hasIossEnrollment ? validateIossId(id) : true;
      })
      .nullable(true),
    iossIDDateOfReceipt: yup
      .string()
      .when('hasIossEnrollment', (hasIossEnrollment: boolean) => {
        return hasIossEnrollment
          ? yup.string().required()
          : yup.string().notRequired().nullable(true);
      }),
  }),
  retroFilings: yup
    .array()
    .of(
      yup.object().shape({
        startDate: yup.string(),
        country: yup.string(),
      }),
    )
    .when('liabilities.hasRetroFilings', {
      is: (val: unknown) => val && val === true,
      then: yup.array().of(
        yup.object().shape({
          startDate: yup.string().required('This field is required'),
          country: yup.string().required('This field is required'),
        }),
      ),
    }),
});

type Props = {
  isFreeTrial: boolean;
  updateState: (type: keyof OnboardingFormState, data: LiabilitiesFormState) => void;
  globalState: OnboardingFormState;
};

// Personal data collection form
export const LiabilitiesForm = ({
  updateState,
  isFreeTrial,
  globalState,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const [vatIds, setVatIds] = useState<AddedVatId[]>(
    globalState.liabilitiesForm.liabilities.vatIds,
  );
  const [isVatValidationNeeded, setIsVatValidationNeeded] = useState(false);
  const [validateVatIdsError, setValidateVatIdsError] = useState(false);
  const [hasInvalidVatId, setHasInvalidVatId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation(['liabilities', 'others']);
  const tPersonal = useTranslation(['others', 'personal'])[0];
  const { countryOptions, euCountries, yesNoOptions } = getPersonalFormOptions(tPersonal);
  const colSpan = useBreakpointValue({ base: 2, md: 1 });
  const FBAEnrollmentTypeOptions = t('liabilities:FBAEnrollmentType.options', {
    returnObjects: true,
  });

  // Initialize RHF with current progress of the personal data form
  const methods = useForm<LiabilitiesFormState>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...globalState.liabilitiesForm,
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    clearErrors,
    setValue,
    formState: { errors },
  } = methods;

  const {
    fields: retroFilingFields,
    append: retroFilingAppend,
    remove: retroFilingRemove,
  } = useFieldArray({
    name: 'retroFilings' as const,
    control,
    shouldUnregister: true,
  });

  // Stupid workaround for the lack of info between data loaded from backend by booking-form vs updated data from auto-onboarding form
  const ossAndIossValidator = useCallback(() => {
    const localstorageIOssEnrollmentValue = localStorage.getItem(
      'liabilities.hasIossEnrollment',
    );
    const localstorageOssEnrollmentValue = localStorage.getItem(
      'liabilities.hasOssEnrollment',
    );

    setValue('liabilities.hasIossEnrollment', null);
    setValue('liabilities.hasOssEnrollment', null);

    if (localstorageIOssEnrollmentValue !== null) {
      setValue(
        'liabilities.hasIossEnrollment',
        JSON.parse(localstorageIOssEnrollmentValue),
      );
    }

    if (localstorageOssEnrollmentValue !== null) {
      setValue(
        'liabilities.hasOssEnrollment',
        JSON.parse(localstorageOssEnrollmentValue),
      );
    }
  }, [setValue]);

  useEffect(() => {
    ossAndIossValidator();
    const ossAndIossWatch = watch((values, { name }) => {
      if (name === 'liabilities.hasOssEnrollment') {
        localStorage.setItem(name, String(values?.liabilities?.hasOssEnrollment));
      }

      if (name === 'liabilities.hasIossEnrollment') {
        localStorage.setItem(name, String(values?.liabilities?.hasIossEnrollment));
      }
    });

    return () => ossAndIossWatch.unsubscribe();
  }, [globalState, reset, watch, ossAndIossValidator]);

  useEffect(() => {
    (async () => {
      if (isVatValidationNeeded) {
        try {
          const validatedIds = await validateVatIds(vatIds);
          if (validatedIds) {
            const vatIdsWithStatus = validatedIds.map((item) => {
              const vatIdObj = vatIds.find((i) => i.vatId === item.vatId);
              return {
                ...vatIdObj,
                ...item,
              };
            });

            setIsVatValidationNeeded(false);
            setVatIds(vatIdsWithStatus);
          }
        } catch (error) {
          setValidateVatIdsError(true);
        }
      }
    })();
  }, [vatIds, isVatValidationNeeded, validateVatIdsError]);

  useEffect(() => {
    const hasUncheckedVatIds = vatIds.some(
      (vat) => vat.validity === VatIdValidity.UNKNOWN,
    );
    const hasInvalidVatId = vatIds.some((vat) => vat.validity === VatIdValidity.INVALID);

    if (hasUncheckedVatIds) {
      setIsVatValidationNeeded(true);
    }

    setHasInvalidVatId(hasInvalidVatId);
  }, [vatIds]);

  const onBackButton = () => {
    clearErrors();
    navigate('/onboarding/personal_data');
  };

  // Click handler for the next button
  const onSubmit = () => {
    const routePath = '/onboarding/vat_specifics';

    return async (data: LiabilitiesFormState) => {
      setIsLoading(true);

      if (!hasRetroFiling) {
        data.retroFilings = [];
      }

      if (!isOSSEnrolled) {
        data.liabilities.ossDateOfApplication = null;
        data.liabilities.firstOssPeriod = null;
      }

      if (!isIOSSEnrolled) {
        data.liabilities.iossId = null;
        data.liabilities.iossIDDateOfReceipt = null;
      }

      if (isOSSEnrolled) {
        data.liabilities.firstOssPeriod = data.liabilities.ossDateOfApplication;
      }

      // Update the form state
      data.liabilities.vatIds = vatIds;

      try {
        await updateState('liabilitiesForm', data);
        navigate(routePath);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
  };

  const isAmazonFBAEnrolled = watch('liabilities.hasFBAEnrollment');
  const isOSSEnrolled = watch('liabilities.hasOssEnrollment');
  const isIOSSEnrolled = watch('liabilities.hasIossEnrollment');
  const hasAdditionalWarehouses = watch('liabilities.hasAdditionalEUWarehouse');
  const hasRetroFiling = watch('liabilities.hasRetroFilings');

  const updateVatIds = ({
    vatId: newVatId,
    validFrom,
    validity,
    taxNumber,
  }: AddedVatId) => {
    const alreadyAddedVatID = vatIds?.some(({ vatId }) => vatId === newVatId);
    if (!alreadyAddedVatID) {
      const newVatIds = [
        ...(vatIds || []),
        { vatId: newVatId, validity, validFrom, taxNumber },
      ];
      setVatIds(newVatIds);
    }
  };

  const isButtonDisable = (): boolean => hasInvalidVatId || vatIds.length === 0;

  const addressCountryCode = useMemo(
    () => globalState.personalForm.address.country,
    [globalState],
  );

  return (
    <div className="lg:grid lg:grid-cols-4 lg:gap-6">
      <FormInfo
        header={t('liabilities:title')}
        description={t('liabilities:sidebarDescription')}
      />

      <div className="md:ml-10 mt-5 md:mt-0 md:col-span-3">
        <FormProvider {...methods}>
          <form>
            <SimpleGrid columns={1} rowGap={6} w="full">
              <VatIdInputView
                addressCountryCode={addressCountryCode as string}
                setVatIds={updateVatIds}
              />
              {vatIds && (
                <ShowVatIds
                  vatIds={vatIds}
                  euCountries={euCountries}
                  setVatIds={(newVatIds) => {
                    const hasInvalidVatId = newVatIds.some(
                      (vat) => vat.validity !== VatIdValidity.VALID,
                    );
                    setHasInvalidVatId(hasInvalidVatId);
                    setVatIds(newVatIds);
                  }}
                />
              )}
            </SimpleGrid>

            <div className="col-span-6 sm:col-span-6 mt-4">
              <RadioSelect
                id="hasFBAEnrollment"
                name="liabilities.hasFBAEnrollment"
                error={errors.liabilities?.hasFBAEnrollment}
                label={t('liabilities:hasFBAEnrollment.label')}
                tooltip={t('liabilities:hasFBAEnrollment.toolTip')}
                options={yesNoOptions}
              />
            </div>

            <div className="col-span-6 sm:col-span-6 mt-4">
              {isAmazonFBAEnrolled && (
                <RadioSelect
                  id="FBAEnrollmentType"
                  name="liabilities.FBAEnrollmentType"
                  error={errors.liabilities?.FBAEnrollmentType}
                  label={t('liabilities:FBAEnrollmentType.label')}
                  tooltip={t('liabilities:FBAEnrollmentType.toolTip')}
                  options={FBAEnrollmentTypeOptions}
                  transformValueToBoolean={false}
                />
              )}
            </div>

            <div className="col-span-6 sm:col-span-6 mt-6">
              <RadioSelect
                id="hasAdditionalEUWarehouse"
                name="liabilities.hasAdditionalEUWarehouse"
                error={errors.liabilities?.hasAdditionalEUWarehouse}
                label={t('liabilities:hasAdditionalEUWarehouse.label')}
                options={yesNoOptions}
              />
              {hasAdditionalWarehouses && (
                <div className="mt-4">
                  <Alert
                    alertType="warning"
                    header={t('liabilities:hasAdditionalEUWarehouse.alertHeader')}
                    message={t('liabilities:hasAdditionalEUWarehouse.alert')}
                  />
                </div>
              )}
            </div>

            {/* OSS */}
            <div className="col-span-6 sm:col-span-6 mt-6">
              <RadioSelect
                id="hasOssEnrollment"
                name="liabilities.hasOssEnrollment"
                error={errors.liabilities?.hasOssEnrollment}
                label={t('liabilities:hasOssEnrollment.label')}
                tooltip={t('liabilities:hasOssEnrollment.toolTip')}
                options={yesNoOptions}
              />
            </div>

            {isOSSEnrolled && (
              <div className="col-span-6 sm:col-span-3 mt-6">
                <DatePicker
                  id="ossDateOfApplication"
                  name="liabilities.ossDateOfApplication"
                  label={t('liabilities:ossDateOfApplication.label')}
                  error={errors.liabilities?.ossDateOfApplication}
                  error_msg={t('liabilities:validation.error.ossDateOfApplication')}
                  minDate={new Date(MIN_DATE_FOR_OSS)}
                  maxDate={new Date(Date.now())}
                />
              </div>
            )}

            {/* IOSS */}
            <div className="col-span-6 sm:col-span-6 mt-6">
              <RadioSelect
                id="hasIossEnrollment"
                name="liabilities.hasIossEnrollment"
                error={errors.liabilities?.hasIossEnrollment}
                label={t('liabilities:hasIossEnrollment.label')}
                tooltip={t('liabilities:hasIossEnrollment.toolTip')}
                options={yesNoOptions}
              />
            </div>

            {isIOSSEnrolled && (
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3 mt-6">
                  <InputField
                    id="iossId"
                    label={t('liabilities:iossId.label')}
                    {...register('liabilities.iossId')}
                    error={errors.liabilities?.iossId}
                    error_msg={t('liabilities:validation.error.iossIdValidation')}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 mt-6">
                  <DatePicker
                    id="iossIDDateOfReceipt"
                    name="liabilities.iossIDDateOfReceipt"
                    label={t('liabilities:iossIDDateOfReceipt.label')}
                    error={errors.liabilities?.iossIDDateOfReceipt}
                    error_msg={t('liabilities:validation.error.iossIDDateOfReceipt')}
                    minDate={new Date(MIN_DATE_FOR_IOSS)}
                    maxDate={new Date(Date.now())}
                  />
                </div>
              </div>
            )}

            {/* Retro Filing */}
            <div className="col-span-6 sm:col-span-6 mt-6">
              <RadioSelect
                id="hasRetroFilings"
                name="liabilities.hasRetroFilings"
                error={errors.liabilities?.hasRetroFilings}
                label={t('liabilities:hasRetroFilings.label')}
                tooltip={t('liabilities:hasRetroFilings.toolTip')}
                options={yesNoOptions}
              />
            </div>

            {hasRetroFiling && (
              <SimpleGrid rowGap={6} mt={6}>
                {retroFilingFields.length !== 0 ? (
                  retroFilingFields.map((field, index) => {
                    return (
                      <SimpleGrid columns={2} columnGap={3} key={field.id}>
                        {/* Retro Filing Country */}
                        <GridItem colSpan={colSpan}>
                          <Select
                            id="retroFilingCountry"
                            label={t('liabilities:retroFilingCountry.label')}
                            {...register(`retroFilings.${index}.country` as const)}
                            options={countryOptions}
                            error={
                              (errors.retroFilings?.[index] as RetroFilingFieldError)
                                ?.country
                            }
                            error_msg={t(
                              'liabilities:validation.error.retroFilingsCountry',
                            )}
                          />
                        </GridItem>
                        {/* Retro Filing Start date */}
                        <GridItem colSpan={colSpan}>
                          <HStack>
                            <DatePicker
                              id="retroFilingStartFrom"
                              name={`retroFilings.${index}.startDate` as const}
                              label={t('liabilities:retroFilingStartDate.label')}
                              error={
                                (errors.retroFilings?.[index] as RetroFilingFieldError)
                                  ?.startDate
                              }
                              error_msg={t(
                                'liabilities:validation.error.retroFilingsDate',
                              )}
                            />
                            {/* Retro filing add & remove buttons */}

                            {index === retroFilingFields.length - 1 ? (
                              <IconButton
                                alignSelf={'flex-end'}
                                bottom={
                                  (errors.retroFilings?.[index] as RetroFilingFieldError)
                                    ?.startDate
                                    ? '1.7em'
                                    : ''
                                }
                                variant="ghost"
                                size={'md'}
                                aria-label="Add retro filing"
                                icon={<Icon as={PlusCircleIcon} w={10} h={10} />}
                                onClick={() => {
                                  retroFilingAppend({
                                    country: '',
                                    startDate: '',
                                  });
                                }}
                              />
                            ) : null}
                            <IconButton
                              alignSelf={'flex-end'}
                              bottom={
                                (errors.retroFilings?.[index] as RetroFilingFieldError)
                                  ?.startDate
                                  ? '1.7em'
                                  : ''
                              }
                              variant="ghost"
                              size={'md'}
                              aria-label="Add retro filing"
                              colorScheme={'red'}
                              icon={<Icon as={XCircleIcon} w={10} h={10} />}
                              onClick={() => retroFilingRemove(index)}
                            />
                          </HStack>
                        </GridItem>
                      </SimpleGrid>
                    );
                  })
                ) : (
                  <SimpleGrid columns={2} columnGap={3}>
                    <GridItem colSpan={colSpan}>
                      <Select
                        id="retroFilingCountry"
                        label={t('liabilities:retroFilingCountry.label')}
                        {...register(`retroFilings.${0}.country` as const)}
                        options={countryOptions}
                        error={
                          (errors.retroFilings?.[0] as RetroFilingFieldError)?.country
                        }
                        error_msg={t('liabilities:validation.error.retroFilingsCountry')}
                      />
                    </GridItem>
                    <GridItem colSpan={colSpan}>
                      <HStack>
                        <DatePicker
                          id="retroFilingStartFrom"
                          name={`retroFilings.${0}.startDate` as const}
                          label={t('liabilities:retroFilingStartDate.label')}
                          error={
                            (errors.retroFilings?.[0] as RetroFilingFieldError)?.startDate
                          }
                          error_msg={t('liabilities:validation.error.retroFilingsDate')}
                        />
                        <IconButton
                          alignSelf={'flex-end'}
                          bottom={
                            (errors.retroFilings?.[0] as RetroFilingFieldError)?.startDate
                              ? '1.7em'
                              : ''
                          }
                          variant="ghost"
                          size={'md'}
                          aria-label="Add retro filing"
                          icon={<Icon as={PlusCircleIcon} w={10} h={10} />}
                          onClick={() => {
                            retroFilingAppend({
                              country: '',
                              startDate: '',
                            });
                          }}
                        />
                      </HStack>
                    </GridItem>
                  </SimpleGrid>
                )}
              </SimpleGrid>
            )}

            {hasInvalidVatId && (
              <div className="col-span-6 sm:col-span-6 mt-6">
                <Alert
                  alertType="error"
                  header={t('liabilities:vatValidationError.alertHeader')}
                  message={t('liabilities:vatValidationError.alert')}
                />
              </div>
            )}

            {validateVatIdsError && (
              <div className="col-span-6 sm:col-span-6 mt-6">
                <Alert
                  alertType="warning"
                  header={t('liabilities:vatIdChecker.warning.header')}
                  message={t('liabilities:vatIdChecker.warning.message')}
                />
              </div>
            )}

            {vatIds.length === 0 && (
              <div className="col-span-6 sm:col-span-6 mt-6">
                <Alert
                  alertType="error"
                  header={t('liabilities:vatIdRequired.alertHeader')}
                  message={t('liabilities:vatIdRequired.alert')}
                />
              </div>
            )}

            <div className="mt-8 flex flex-wrap items-center justify-between">
              <CustomerSupport isFreeTrial></CustomerSupport>
              <div className="mt-8 py-3 text-right sm:px-3 md:px-0 flex justify-end">
                <Button variant="outline" onClick={onBackButton}>
                  {t('others:button.back.label')}
                </Button>
                <Button
                  color={'white'}
                  ml={2}
                  disabled={isButtonDisable()}
                  onClick={handleSubmit(onSubmit())}
                  isLoading={isLoading}>
                  {t('others:button.next.label')}
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
