// If the IOSS ID contains IM and 10 numeric digits then true otherwise false
export const validateIossId = (Id: string | undefined): boolean => {
  const charRegex = /[a-zA-Z]/g;
  return Id && Id.substring(0, 2) === 'IM'
    ? charRegex.test(Id.substring(2))
      ? false
      : Id.substring(2).length === 10
      ? true
      : false
    : false;
};
