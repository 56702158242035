import { useAuth } from '../context/auth';
import {
  AddressInput,
  ConnectedSystemsInput,
  LiabilitiesInput,
  MetadataInput,
  PersonalInput,
  RetroFilingInput,
  Status,
  useGetClientv2Query,
  useUpdateClientv2Mutation,
  VatSpecificsInput,
} from '../graphql/generated/graphql';
import { API_NAMES } from '../utils/constants';

// Max number of tracked metadata sessions per user
const MAX_SESSION_COUNT = 40;

/**
 * An updated list of all user's session metadata
 */
let sessionsList: MetadataInput[];

/**
 * Returns functions to update the current client session metadata.
 */
function useSessionMetadata() {
  const { clientId } = useAuth();
  const [updateClientv2] = useUpdateClientv2Mutation();
  const { data } = useGetClientv2Query({
    context: { clientName: API_NAMES.AO_API },
    variables: { id: clientId },
  });

  // the metadata attribute can be undefined | single session object (old) or an array of sessions (new)
  // flattening the array guarantees we have the correct format (array of sessions)
  sessionsList = [data?.getClientv2?.metadata || []].flat() as MetadataInput[];
  /**
   * Update the session metadata for a client
   * Use the more specific methods {@link trackSessionStart} or {@link trackSessionCanceled} to track a session start or cancel
   * @param sessionMetadata the new session metadata object
   */
  const updateSessionMetadata = async (session: MetadataInput, isNew = false) => {
    setCurrentSession(session);

    if (!isNew) {
      // Update the latest session (latest session at the start)
      sessionsList[0] = session;
    } else {
      // Add a new session to the list
      sessionsList.unshift(session);
    }

    if (sessionsList.length > MAX_SESSION_COUNT) {
      sessionsList.length = MAX_SESSION_COUNT;
    }

    try {
      const newClientData = {
        context: { clientName: API_NAMES.AO_API },
        variables: {
          id: clientId,
          // TODO: refactor to only doing a partial update [HAOF-1052]
          // ----------------------------------------
          stripeId: data?.getClientv2?.stripeId || '',
          status: data?.getClientv2?.status as Status,
          address: data?.getClientv2?.address as AddressInput,
          personal: data?.getClientv2?.personal as PersonalInput,
          liabilities: data?.getClientv2?.liabilities as LiabilitiesInput,
          vatIds: data?.getClientv2?.vatIds,
          retroFilings: data?.getClientv2?.retroFilings as RetroFilingInput,
          vatSpecifics: data?.getClientv2?.vatSpecifics as VatSpecificsInput,
          connectedSystems: data?.getClientv2?.connectedSystems as ConnectedSystemsInput,
          // ----------------------------------------
          metadata: sessionsList,
        },
      };
      const res = await updateClientv2(newClientData);

      sessionsList = [
        ...(res?.data?.updateClientv2?.metadata || []),
      ].flat() as MetadataInput[];
    } catch (error) {
      console.error(
        'There was an error while trying to update the session metadata',
        error,
      );
    }
  };

  /**
   * Track the start of a new AO session
   */
  const trackSessionStart = () => {
    const now = new Date();
    const newSession = {
      sessionStarted: now.toISOString(),
      pageOneCompleted: null,
      pageTwoCompleted: null,
      pageThreeCompleted: null,
      pageFourCompleted: null,
      sessionCanceled: null,
    };

    updateSessionMetadata(newSession, true);
  };

  /**
   * Track the abandonment of the current AO session
   */
  const trackSessionCanceled = () => {
    const currentSession = getCurrentSession();
    if (currentSession) {
      const sessionMetadata = {
        sessionStarted: currentSession.sessionStarted,
        pageOneCompleted: currentSession.pageOneCompleted,
        pageTwoCompleted: currentSession.pageTwoCompleted,
        pageThreeCompleted: currentSession.pageThreeCompleted,
        pageFourCompleted: currentSession.pageFourCompleted,
        sessionCanceled: new Date().toISOString(),
      };

      updateSessionMetadata(sessionMetadata);
    } else {
      console.error('Current session not found');
    }
  };

  const setCurrentSession = (session: MetadataInput) => {
    window.sessionStorage.setItem('currentSession', JSON.stringify(session));
  };

  const getCurrentSession = (): MetadataInput | null => {
    const currentSession = window.sessionStorage.getItem('currentSession');
    return currentSession ? (JSON.parse(currentSession) as MetadataInput) : null;
  };

  return {
    getCurrentSession,
    setCurrentSession,
    trackSessionStart,
    trackSessionCanceled,
    updateSessionMetadata,
    sessionsList,
  };
}
export default useSessionMetadata;
