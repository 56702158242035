import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldProps } from '../../utils/constants/types';
import FormField from './FormField';

type Props = FieldProps & {
  name: string;
  transformValueToBoolean?: boolean;
  options: {
    label: string;
    value: string;
  }[];
};

export const RadioSelect = ({
  name,
  transformValueToBoolean = true,
  ...props
}: Props): JSX.Element => {
  const { control } = useFormContext();

  return (
    <FormField {...props}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <RadioGroup
              onChange={(value) =>
                field.onChange(transformValueToBoolean ? value === 'true' : value)
              }
              value={String(field.value)}>
              <Stack direction="row">
                {props.options.map(({ value, label }) => (
                  <Radio value={String(value)} key={label}>
                    {label}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          );
        }}
      />
    </FormField>
  );
};

export default RadioSelect;
