const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const URLRegex =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const COMPANY_NAME_REGX = /^[A-Za-zÀ-ÿ/\d/\s-&.,+/()'#!:[/\]]*$/;

const ALPHA_POINT_DASH_REGX = /^[a-zA-ZÀ-ÿ/\s.-]*$/;

const ALPHA_NUMERIC_SPACE_DASH_REGX = /[^a-zA-ZÀ-ÿ0-9\s/-]/g;

const ALPHA_NUMERIC_POINT_DASH_REGX =
  /^(?:[a-zA-ZÀ-ÿ0-9?:'-? ]+(?:,[-][a-zA-ZÀ-ÿ0-9?:' ]+)*)?$/;

export {
  ALPHA_NUMERIC_POINT_DASH_REGX,
  ALPHA_NUMERIC_SPACE_DASH_REGX,
  ALPHA_POINT_DASH_REGX,
  COMPANY_NAME_REGX,
  emailRegex,
  URLRegex,
};
export default {
  emailRegex,
  URLRegex,
  COMPANY_NAME_REGX,
  ALPHA_POINT_DASH_REGX,
  ALPHA_NUMERIC_POINT_DASH_REGX,
  ALPHA_NUMERIC_SPACE_DASH_REGX,
};
