import { MailIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';

const DialogueForm = (): JSX.Element => {
  const { t } = useTranslation('support');

  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              {t('contact')}
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">{t('description')}</p>
            <dl className="mt-8 text-base text-gray-500">
              <div className="mt-3">
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  {/* TODO: support@taxdoo.com is deprecated */}
                  <span className="ml-3">{t('emailSupport')}</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-4 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form action="#" method="POST" className="grid grid-cols-1 gap-y-3">
              <div>
                <label htmlFor="full-name" className="sr-only">
                  {t('fullName')}
                </label>
                <input
                  type="text"
                  name="full-name"
                  id="full-name"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder={t('fullName')}
                  defaultValue={''}
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  {t('yourEmail')}
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder={t('yourEmail')}
                  defaultValue={''}
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  {t('phone')}
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder={t('phone')}
                  defaultValue={''}
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  {t('message')}
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block outline: none; w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                  placeholder={t('messageDescription')}
                  defaultValue={''}
                />
              </div>
              <div>
                <Button label="SUBMIT" onClick={() => console.log('need to implement')}>
                  {t('submit')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogueForm;
