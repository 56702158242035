import { Center, Fade, Spinner, useToast } from '@chakra-ui/react';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { Alert } from '../components/alerts';
import { BackToDashboard } from '../components/layout/backToDashboard/BackToDashboard';
import { Steps } from '../components/layout/navigation/Steps';
import { useAuth } from '../context/auth';
import {
  ConnectedSystems,
  GetClientv2QueryHookResult,
  LiabilitiesInputv2,
  PersonalInput,
  Status,
  useGetClientv2Query,
  useUpdateClientv2Mutation,
  VatSpecifics,
} from '../graphql/generated/graphql';
import useSessionMetadata from '../hooks/sessionMetadata';
import { API_NAMES, INITIAL_STATE, SUCCESS_PAGE_PATH } from '../utils/constants';
import {
  LiabilitiesFormState,
  OnboardingFormState,
  PersonalFormState,
} from '../utils/constants/types';
import { getFormState } from '../utils/helpers';
import { ConnectedSystemsForm } from './ConnectedSystemsForm';
import { EndPage } from './EndPage';
import { LiabilitiesForm } from './LiabilitiesForm';
import { NotFoundPage } from './NotFoundPage';
import { PersonalDataForm } from './PersonalDataForm';
import { VatSpecificsForm } from './VatSpecificsForm';

export const OnboardingForms = (): JSX.Element => {
  const { clientId } = useAuth();
  const location = useLocation();
  // Whether or not the onboarding reached the success page
  const [isDone, setIsDone] = useState(false);
  const [updateClientv2] = useUpdateClientv2Mutation();
  const { getCurrentSession, setCurrentSession, sessionsList } = useSessionMetadata();
  const { t } = useTranslation(['home', 'error']);
  const [formState, setFormState] = useState<OnboardingFormState>(
    getFormState(INITIAL_STATE),
  );
  const { loading, data, error }: GetClientv2QueryHookResult = useGetClientv2Query({
    context: { clientName: API_NAMES.AO_API },
    variables: { id: clientId },
  });

  const isFreeTrial = data?.getClientv2?.packageInformation?.isFreeTrial || false;
  const isReopened = Boolean(data?.getClientv2?.reopenedAt);

  const getClientStatus = !loading && data && data.getClientv2?.status;

  useEffect(() => {
    setIsDone(location.pathname === SUCCESS_PAGE_PATH);
  }, [location]);

  useEffect(() => {
    let newState: OnboardingFormState = data
      ? getFormState(INITIAL_STATE, data)
      : INITIAL_STATE;

    if (isFreeTrial && data?.getClientv2?.address?.street?.includes('Valentinskamp')) {
      newState = {
        ...INITIAL_STATE,
        personalForm: {
          ...INITIAL_STATE.personalForm,
          address: {
            ...INITIAL_STATE.personalForm.address,
            firstName: data?.getClientv2?.address?.firstName,
            lastName: data?.getClientv2?.address?.lastName,
          },
        },
      };
    }

    setFormState(newState);
  }, [data, isFreeTrial]);

  const toast = useToast();

  const updateData = async (
    formType: keyof OnboardingFormState,
    newData: PersonalFormState | LiabilitiesFormState | VatSpecifics | ConnectedSystems,
  ): Promise<void> => {
    const newState = {
      ...formState,
      [formType]: { ...newData },
    };

    const { vatIds, ...liabilities } = newState.liabilitiesForm.liabilities;

    // dataLayer start
    let step = 0;
    switch (formType) {
      case 'personalForm':
        step = 1;
        break;

      case 'liabilitiesForm':
        step = 2;
        break;

      case 'vatSpecificsForm':
        step = 3;
        break;

      case 'connectedSystemsForm':
        step = 4;
        break;

      default:
        break;
    }

    (window as any)?.dataLayer.push({
      event: `auto_onbording_step_${step}`,
      user_data: {
        client_id: clientId,
      },
    });
    // dataLayer end

    const now = new Date().toISOString();
    const currentSession = getCurrentSession();
    // TODO: use sessionMetadata hook when partial update is implemented [HAOF-1052]
    const updatedCurrentSession = {
      sessionStarted: currentSession?.sessionStarted || null,
      pageOneCompleted: step === 1 ? now : currentSession?.pageOneCompleted || null,
      pageTwoCompleted: step === 2 ? now : currentSession?.pageTwoCompleted || null,
      pageThreeCompleted: step === 3 ? now : currentSession?.pageThreeCompleted || null,
      pageFourCompleted: step === 4 ? now : currentSession?.pageFourCompleted || null,
      sessionCanceled: currentSession?.sessionCanceled || null,
    };
    setCurrentSession(updatedCurrentSession);
    const sessionsListCopy = [updatedCurrentSession, ...sessionsList];
    try {
      const newClientData = {
        context: { clientName: API_NAMES.AO_API },
        variables: {
          id: clientId,
          stripeId: data?.getClientv2?.stripeId || '',
          status: formType === 'connectedSystemsForm' ? Status.Done : Status.Pending,
          address: newState?.personalForm.address,
          personal: newState?.personalForm.personal as PersonalInput,
          liabilities: liabilities as LiabilitiesInputv2,
          vatIds: vatIds.map(({ vatId, validFrom, taxNumber }) => ({
            vatId,
            validFrom,
            taxNumber,
          })),
          retroFilings: newState?.liabilitiesForm.retroFilings,
          vatSpecifics: newState?.vatSpecificsForm,
          connectedSystems: newState?.connectedSystemsForm,
          metadata: sessionsListCopy,
        },
      };
      const res = await updateClientv2(newClientData);
      if (res.errors) {
        throw new Error();
      } else {
        setFormState(newState);
        return Promise.resolve();
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while trying to update the client data.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return Promise.reject(
        new Error('An error occurred while trying to update the client data'),
      );
    }
  };

  if (loading) {
    return (
      <Fade in={loading}>
        <Center h="30vh" color="white">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="grey.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      </Fade>
    );
  }

  if (error) {
    return (
      <main className="px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <Alert
            alertType="error"
            header={t('error:generic.title')}
            message={t('error:generic.text')}
          />
          <NotFoundPage />
        </div>
      </main>
    );
  }

  if (getClientStatus && getClientStatus === 'DONE') {
    return (
      <div className="text-center">
        <main className="max-w-7xl mx-auto py-5 sm:px-6 md:px-8 h-full max-h-full">
          <div className="bg-white overflow-hidden shadow sm:rounded-lg">
            <div className="px-4 py-6 sm:px-0">
              <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-6 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 xl:mb-28">
                <div className="text-center">
                  <Alert
                    backgroundColor="#FFFFFF"
                    alertType="success"
                    header={t('home:statusDoneMessage')}
                    iconBoxSize="50px"
                    spaceBetween="20px"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    height="200px"></Alert>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

  return (
    <main className="max-w-7xl mx-auto py-5 sm:px-6 md:px-8 h-full max-h-full">
      <div className="mb-5">
        <Steps />
      </div>

      {formState && (
        <div>
          <div className="space-y-6">
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <Routes>
                <Route index element={<Navigate to="personal_data" />} />
                <Route
                  path={'personal_data'}
                  element={
                    <PersonalDataForm
                      state={formState.personalForm}
                      isFreeTrial={isFreeTrial}
                      isReopened={isReopened}
                      updateState={updateData}
                    />
                  }
                />
                <Route
                  path={'vat_data_liabilities'}
                  element={
                    <LiabilitiesForm
                      globalState={formState}
                      isFreeTrial={isFreeTrial}
                      updateState={updateData}
                    />
                  }
                />
                <Route
                  path={'vat_specifics'}
                  element={
                    <VatSpecificsForm
                      state={formState.vatSpecificsForm}
                      isFreeTrial={isFreeTrial}
                      updateState={updateData}
                    />
                  }
                />
                <Route
                  path={'connected_systems'}
                  element={
                    <ConnectedSystemsForm
                      state={formState.connectedSystemsForm}
                      isFreeTrial={isFreeTrial}
                      updateState={updateData}
                    />
                  }
                />
                <Route path={'end'} element={<EndPage isFreeTrial={isFreeTrial} />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </div>
            {isFreeTrial && !isDone && <BackToDashboard />}
          </div>
        </div>
      )}
    </main>
  );
};

export default memo(OnboardingForms);
