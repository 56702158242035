import { Input, InputProps } from '@chakra-ui/react';
import React from 'react';

import { FieldProps } from '../../utils/constants/types';
import FormField from './FormField';

export type Props = InputProps & FieldProps;

export const InputField = React.forwardRef<HTMLInputElement, Props>(
  ({ id, ...props }, ref) => (
    <FormField {...{ id, ...props }}>
      <Input id={id} type={props.type} ref={ref} {...props} />
    </FormField>
  ),
);

export default InputField;
