import React, { FC, PropsWithChildren } from 'react';

import { Spinner } from './Spinner';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface SharedProps {
  label?: string;
  disabled?: boolean;
  secondary?: boolean;
  className?: string;
}

type CondictionalProps =
  | {
      type?: 'button';
      loader?: boolean;
      onClick: () => void;
      href?: never;
      target?: never;
    }
  | {
      type?: 'link';
      href: string;
      target?: string;
      loader?: never;
      onClick?: never;
    };

type ButtonProps = SharedProps & CondictionalProps;

export const Button: FC<PropsWithChildren<ButtonProps>> = (props) => {
  const {
    type = 'button',
    className = '',
    secondary,
    disabled,
    children,
    loader,
    onClick,
    href,
    target = '_self',
  } = props;
  const classes = classNames(
    className,
    'inline-flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
    secondary
      ? 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-500'
      : 'border-transparent text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500',
    disabled
      ? `${
          type === 'button' ? 'cursor-not-allowed' : 'pointer-events-none'
        } bg-opacity-75 hover:bg-opacity-75`
      : '',
  );

  if (type === 'button') {
    return (
      <button className={classes} type="button" onClick={onClick} disabled={disabled}>
        {loader ? (
          <div className="flex">
            <Spinner />
            <span>{children}</span>
          </div>
        ) : (
          <span>{children}</span>
        )}
      </button>
    );
  }

  return (
    <a className={classes} href={href} target={target}>
      <span>{children}</span>
    </a>
  );
};
