import { Disclosure } from '@headlessui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import TaxdooLogo from '../../../assets/logo.svg';
import { LangSwitch } from './LangSwitch';

export const NavBar = (): JSX.Element => (
  <Disclosure as="nav" className="bg-white shadow">
    {() => (
      <>
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex justify-between h-16">
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                <img
                  className="block h-8 w-auto"
                  src={TaxdooLogo}
                  alt="Taxdoo Auto Onboarding"
                />
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              {/* Language dropdown */}
              <LangSwitch />
            </div>
          </div>
        </div>
      </>
    )}
  </Disclosure>
);
