import {
  Alert as AlertBox,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Box,
} from '@chakra-ui/react';
import Markdown from 'markdown-to-jsx';
import React from 'react';

import { AlertType } from './helper';

type AlertProp = {
  alertType: AlertType;
  header?: string;
  message?: string;
  iconBoxSize?: string;
  spaceBetween?: string;
} & AlertProps;

export const Alert = ({
  alertType,
  header,
  message,
  iconBoxSize,
  spaceBetween,
  ...props
}: AlertProp): JSX.Element => {
  return (
    <AlertBox status={alertType} {...props}>
      <AlertIcon boxSize={iconBoxSize} />
      <Box flex="1" mt={spaceBetween ?? ''}>
        <AlertTitle>{header}</AlertTitle>
        <AlertDescription display="block">
          <Markdown
            options={{
              overrides: {
                a: {
                  component: 'a',
                  props: {
                    className: 'font-medium underline text-blue-700 hover:text-blue-600',
                    rel: 'noopener noreferrer',
                    target: '_blank',
                  },
                },
              },
            }}>
            {message ?? ''}
          </Markdown>
        </AlertDescription>
      </Box>
    </AlertBox>
  );
};
