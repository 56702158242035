import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import React from 'react';
import { useTranslation } from 'react-i18next';

import GermanFlag from '../../../assets/deutsch.svg';
import EnglishFlag from '../../../assets/english.svg';
import { classNames } from '../../../utils/helpers/classNames';

const supportedLangs = [
  {
    key: 'de',
    name: 'Deutsch',
    flag: GermanFlag,
  },
  {
    key: 'en',
    name: 'English',
    flag: EnglishFlag,
  },
];

export const LangSwitch = (): JSX.Element => {
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = React.useState(() => {
    return supportedLangs.find((lang) => lang.key === i18n.language);
  });
  const changeLanguage = (lngkey: string) => {
    i18n.changeLanguage(lngkey);
    setSelectedLang(supportedLangs.find((lang) => lang.key === lngkey));
  };

  return (
    <Menu as="div" className="relative inline-block text-left z-50">
      <div>
        <Menu.Button className="dark:bg-gray-600 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          <img
            className="h-4 w-auto"
            src={selectedLang?.flag}
            alt="Taxdoo Auto Onboarding"
          />
          <ChevronDownIcon className="-mr-1 ml-2 h-4 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
        <div className="py-1">
          {supportedLangs.map((lang) => (
            <Menu.Item key={lang.key}>
              {({ active }) => (
                <span
                  onClick={() => changeLanguage(lang.key)}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  )}>
                  <img
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    src={lang.flag}
                    alt="Taxdoo Auto Onboarding"
                  />
                  {lang.name}
                </span>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Menu>
  );
};
