import jwt_decode from 'jwt-decode';
import React, { createContext, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useApiPost } from '../hooks/fetchURL';

export const AuthContext = createContext<{
  authToken: string | null;
  loading: boolean;
  clientId: number;
}>({
  authToken: null,
  loading: false,
  clientId: 0,
});

type ContextProps = {
  setToken: (t: string) => void;
};
export const AuthTokenUpdateContext = createContext<ContextProps>({
  setToken: (t) => console.log(t),
});

export function useAuth() {
  return useContext(AuthContext);
}

export function useAuthUpdate() {
  return useContext(AuthTokenUpdateContext);
}

type Props = {
  children: React.ReactNode;
};
export function AuthProvider({ children }: Props) {
  const [searchParams] = useSearchParams();
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [clientId, setClientId] = useState<number>(0);

  const shortJWT = searchParams.get('jwt');
  const { data, error, loading } = useApiPost<{ jwt: string }>('/validate', {
    jwt: shortJWT === null ? localStorage.getItem('jwt') : shortJWT,
  });

  React.useEffect(() => {
    if (!data || error) {
      setAuthToken(null);
    } else {
      setAuthToken(data.jwt as string);
      localStorage.setItem('jwt', data.jwt as string);
      const { sub: clientId } = jwt_decode<{ sub: string }>(data.jwt);

      setClientId(Number(clientId));
    }
  }, [data, error]);

  return (
    <AuthContext.Provider value={{ authToken, loading, clientId }}>
      <AuthTokenUpdateContext.Provider value={{ setToken: setAuthToken }}>
        {children}
      </AuthTokenUpdateContext.Provider>
    </AuthContext.Provider>
  );
}
