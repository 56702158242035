import { Center, Fade, Spinner } from '@chakra-ui/react';
import Markdown from 'markdown-to-jsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Alert } from '../components/alerts';
import { Button } from '../components/Button';
import { ConfirmationDialog } from '../components/confirmation/ConfirmationDialog';
import { BackToDashboard } from '../components/layout/backToDashboard/BackToDashboard';
import { useAuth } from '../context/auth';
import {
  GetClientv2QueryHookResult,
  Status,
  useGetClientv2Query,
} from '../graphql/generated/graphql';
import useSessionMetadata from '../hooks/sessionMetadata';
import { API_NAMES } from '../utils/constants';

export const HomePage = (): JSX.Element => {
  const navigate = useNavigate();
  const { clientId, loading: isAuthInProgress } = useAuth();

  const { t } = useTranslation(['home', 'error']);
  const { trackSessionStart, trackSessionCanceled } = useSessionMetadata();

  const { data, loading: isClientLoading }: GetClientv2QueryHookResult =
    useGetClientv2Query({
      context: { clientName: API_NAMES.AO_API },
      variables: { id: clientId },
    });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  // The tracking of a session starts when the user visits the home page
  useEffect(() => {
    if (isClientLoading === false) {
      trackSessionStart();
    }
  }, [isClientLoading]);
  /**
   * Handle confirmation action by navigating user back to the MDB
   */
  const onConfirmHandler = async () => {
    // Push event to data layer
    (window as any)?.dataLayer.push({
      event: `auto_onboarding_cancel`,
      user_data: {
        client_id: clientId,
      },
    });
    trackSessionCanceled();

    window.location.href = import.meta.env.VITE_MDB_URL as string;
  };

  const isFreeTrial = data?.getClientv2?.packageInformation?.isFreeTrial || false;
  const getClientStatus = !isClientLoading && data && data.getClientv2?.status;

  const content = t(
    isFreeTrial ? 'home:freeTrial.welcomeMessage' : 'home:welcomeMessage',
  );

  const onAOStart = async () => {
    // Push event to data layer
    (window as any)?.dataLayer.push({
      event: `auto_onboarding_login`,
      user_data: {
        client_id: clientId,
      },
    });
    navigate('onboarding');
  };

  if (isAuthInProgress || isClientLoading) {
    return (
      <Fade in={true}>
        <Center h="30vh" color="white">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="grey.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      </Fade>
    );
  } else {
    return (
      <main className="max-w-7xl mx-auto py-5 sm:px-6 md:px-8 h-full max-h-full">
        <div className="bg-white overflow-hidden shadow sm:rounded-lg">
          <div className="px-4 py-6 sm:px-0">
            <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-6 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 xl:mb-28">
              {getClientStatus === Status.Pending ? (
                <div className="text-center">
                  <h4 className="text-xl font-bold font-sans tracking-tight leading-10 text-gray-800 sm:leading-none">
                    {t('home:title')}
                  </h4>
                  <div className="text-md mt-3 font-mw text-sub-hl-18 text-gray-600 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5">
                    <Markdown>{content}</Markdown>
                  </div>
                  <div className="mt-5 mb-10 sm:mt-8 flex justify-center">
                    <div className="mx-auto">
                      <div className="flex flex-row items-center text-center mt-8 mx-3">
                        {isFreeTrial && (
                          <a
                            onClick={() => setShowConfirmationDialog(true)}
                            className="text-s font-semibold text-blue-600 mx-8 cursor-pointer">
                            {t('home:backToDashboard.button.back')}
                          </a>
                        )}
                        <Button onClick={onAOStart}>
                          {t(
                            isFreeTrial
                              ? 'home:freeTrial.button.start'
                              : 'home:button.start',
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <Alert
                    backgroundColor="#FFFFFF"
                    alertType={getClientStatus === Status.Done ? 'success' : 'error'}
                    header={t(
                      getClientStatus === Status.Done
                        ? 'home:statusDoneMessage'
                        : 'error:401.title',
                    )}
                    message={getClientStatus === Status.Done ? '' : t('error:401.text')}
                    iconBoxSize="50px"
                    spaceBetween="20px"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    height="200px"></Alert>
                </div>
              )}
            </div>
          </div>
        </div>
        {!isFreeTrial && <BackToDashboard />}
        <ConfirmationDialog
          dialogHeader={t('home:backToDashboard.header')}
          dialogText={t('home:backToDashboard.body')}
          confirmBtnText={t('home:backToDashboard.button.confirm')}
          cancelBtnText={t('home:backToDashboard.button.cancel')}
          onConfirm={onConfirmHandler}
          onCancel={() => setShowConfirmationDialog(false)}
          isOpen={showConfirmationDialog}
        />
      </main>
    );
  }
};
