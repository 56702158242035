import { Button, HStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Alert } from '../components/alerts';
import { DatePicker, InputField, RadioSelect, Select } from '../components/forms';
import { FormInfo } from '../components/forms/FormInfo';
import CustomerSupport from '../components/supportForm/SupportModal';
import { getPersonalSchema } from '../utils/constants';
import { OnboardingFormState, PersonalFormState } from '../utils/constants/types';
import { getPersonalFormOptions } from '../utils/helpers';

type Props = {
  state: PersonalFormState;
  isFreeTrial: boolean;
  isReopened: boolean;
  updateState: (type: keyof OnboardingFormState, data: PersonalFormState) => void;
};

// Personal data collection form
export const PersonalDataForm = ({
  state,
  isFreeTrial,
  isReopened,
  updateState,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const { t } = useTranslation(['personal', 'others']);
  const { yesNoOptions, countryOptions } = getPersonalFormOptions(t);

  const [isLoading, setIsLoading] = useState(false);

  // Initialize RHF with current progress of the personal data form
  const methods = useForm<PersonalFormState>({
    resolver: yupResolver(getPersonalSchema(isFreeTrial, countryOptions)),
    defaultValues: {
      ...state,
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitted },
  } = methods;

  const isContractSigned = watch('personal.hasDataProcessingConsented');
  const isEntityTypeNatural = watch('personal.entityType') === 'NATURAL';

  const translatedEntity =
    (state.personal.entityType as string) === 'LEGAL'
      ? t('personal:entityType.LEGAL')
      : (state.personal.entityType as string) === 'PARTNERSHIP'
      ? t('personal:entityType.PARTNERSHIP')
      : (state.personal.entityType as string) === 'PARTNERSHIP_COMPLEMENTARY'
      ? t('personal:entityType.PARTNERSHIP_COMPLEMENTARY')
      : (state.personal.entityType as string) === 'NATURAL'
      ? t('personal:entityType.NATURAL')
      : '';

  const entityType = {
    label: translatedEntity,
    value: translatedEntity,
  };

  const entityTypeOptions = [
    {
      label: t('personal:entityType.LEGAL'),
      value: 'LEGAL',
    },
    {
      label: t('personal:entityType.PARTNERSHIP'),
      value: 'PARTNERSHIP',
    },
    {
      label: t('personal:entityType.PARTNERSHIP_COMPLEMENTARY'),
      value: 'PARTNERSHIP_COMPLEMENTARY',
    },
    {
      label: t('personal:entityType.NATURAL'),
      value: 'NATURAL',
    },
  ];

  const MIN_LEGAL_CLIENT_AGE = 18;
  const maxBirthdayDate = new Date();
  maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - MIN_LEGAL_CLIENT_AGE);

  useEffect(() => {
    reset(state);
  }, [state, reset]);

  // Click handler for the next button
  const onSubmit = (direction: 'next' | 'back') => {
    const routePath = direction === 'next' ? '/onboarding/vat_data_liabilities' : '/';

    return async (data: PersonalFormState) => {
      setIsLoading(true);

      try {
        await updateState('personalForm', data);
        navigate(routePath);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
  };
  return (
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <FormInfo
        header={t('personal:title')}
        description={t('personal:sidebarDescription')}
      />
      <div className="mt-5 md:mt-0 md:col-span-2">
        <FormProvider {...methods}>
          <form>
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6 md:p-0">
              {/* Legal name */}
              <InputField
                isDisabled={isReopened}
                id="legalName"
                label={t('personal:legalName.label')}
                tooltip={t('personal:legalName.toolTip')}
                error={errors.personal?.legalName}
                {...register('personal.legalName')}
              />
              {/* Entity type */}
              {!isFreeTrial ? (
                <Select
                  isDisabled={true}
                  id="entityType"
                  defaultValue={state.personal.entityType as string}
                  label={t('personal:entityType.label')}
                  tooltip={t('personal:entityType.toolTip')}
                  error={errors.personal?.entityType}
                  options={[entityType]}
                />
              ) : (
                <Select
                  isDisabled={isReopened}
                  id="entityType"
                  defaultValue={state.personal.entityType as string}
                  label={t('personal:entityType.label')}
                  tooltip={t('personal:entityType.toolTip')}
                  error={errors.personal?.entityType}
                  options={entityTypeOptions}
                  {...register('personal.entityType')}
                />
              )}

              <HStack gap={3}>
                {/* First Name */}
                <InputField
                  isDisabled={isReopened}
                  id="firstName"
                  label={t('personal:address.firstName')}
                  error={errors.address?.firstName}
                  {...register('address.firstName')}
                />

                {/* Last Name */}
                <InputField
                  isDisabled={isReopened}
                  id="lastName"
                  label={t('personal:address.lastName')}
                  error={errors.address?.lastName}
                  {...register('address.lastName')}
                />
              </HStack>

              {/* Date of Birth */}
              {isEntityTypeNatural && (
                <DatePicker
                  maxDate={maxBirthdayDate}
                  id="dateOfBirth"
                  name="address.dateOfBirth"
                  label={t('personal:address.dateOfBirth')}
                  error={errors.address?.dateOfBirth}
                  dateFormat="dd.MM.yyyy"
                />
              )}

              {/* Street Address */}
              <InputField
                isDisabled={isReopened}
                id="street"
                label={t('personal:address.street')}
                error={errors.address?.street}
                {...register('address.street')}
              />

              <HStack gap={3}>
                {/* City */}
                <InputField
                  isDisabled={isReopened}
                  id="city"
                  label={t('personal:address.city')}
                  error={errors.address?.city}
                  {...register('address.city')}
                />

                {/* State */}
                <InputField
                  isDisabled={isReopened}
                  id="state"
                  label={t('personal:address.state')}
                  error={errors.address?.state}
                  {...register('address.state')}
                />
              </HStack>

              <HStack gap={3}>
                {/* Postal */}
                <InputField
                  isDisabled={isReopened}
                  id="postal"
                  label={t('personal:address.postal')}
                  error={errors.address?.postal}
                  {...register('address.postal')}
                />

                {/* Country */}
                <Select
                  isDisabled={isReopened}
                  id="country"
                  label={t('personal:address.country')}
                  error={errors.address?.country}
                  {...register('address.country')}
                  options={countryOptions}
                />
              </HStack>

              <RadioSelect
                id="hasDataProcessingConsented"
                name="personal.hasDataProcessingConsented"
                label={t('personal:hasDataProcessingConsented.label')}
                tooltip={t('personal:hasDataProcessingConsented.toolTip')}
                options={yesNoOptions}
              />

              {!isContractSigned && (
                <div className="mt-4">
                  <Alert
                    alertType={isSubmitted ? 'error' : 'warning'}
                    header={t('personal:hasDataProcessingConsented.alertHeader')}
                    message={t('personal:hasDataProcessingConsented.alert')}
                  />
                </div>
              )}
            </div>

            <div className="mt-8 flex flex-wrap items-center justify-between">
              <CustomerSupport isFreeTrial></CustomerSupport>
              <div className="py-3 text-right sm:px-3 md:px-0 flex justify-end">
                <Button
                  isLoading={isLoading}
                  color={'white'}
                  ml={2}
                  onClick={handleSubmit(onSubmit('next'))}>
                  {t('others:button.next.label')}
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
