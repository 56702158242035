import { ApolloProvider } from '@apollo/client';
import { Center, Fade, Spinner } from '@chakra-ui/react';
import { AwsRum } from 'aws-rum-web';
import React, { useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { getGraphqlClient } from './apolloClient';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import { Footer } from './components/layout/footer/Footer';
import { NavBar } from './components/layout/navigation/NavBar';
import { useAuth } from './context/auth';
import RequireAuth from './routes/requireAuth';
import { AwsRumService } from './services/CloudWatchRUMService';
import { stopBrowserBack } from './utils/helpers/browserNavigationControl';
import { HomePage } from './views/HomePage';
import { NotFoundPage } from './views/NotFoundPage';
import { OnboardingForms } from './views/OnboardingForms';

type ConsentAnswer = 'ACCEPT' | 'DENY';
type ConsentObject = {
  ESSENTIAL: ConsentAnswer;
  STORAGE: ConsentAnswer;
  MARKETING: ConsentAnswer;
  PERSONALIZATION: ConsentAnswer;
  ANALYTICS: ConsentAnswer;
  OPT_OUT: ConsentAnswer;
};

let awsRum: AwsRum | undefined;

// The layout of the application.
export const App = (): JSX.Element => {
  React.useEffect(() => {
    stopBrowserBack();
    // disable CWR in localhost
    awsRum = ['localhost', '127.0.0.1'].includes(location.hostname)
      ? undefined
      : AwsRumService();
    cookieConsentHandler();
  }, []);

  const { authToken, loading: isAuthInProgress } = useAuth();

  const { pathname } = useLocation();

  // scrolling back to top on route change
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  /**
   * Listen and handles consent preferences changes
   */
  const cookieConsentHandler = () => {
    const initialAnalyticsConsent = window?.Osano?.cm?.getConsent()?.ANALYTICS;
    awsRum?.allowCookies(initialAnalyticsConsent === 'ACCEPT');
    // This event will dispatch when the visitor saves their consent preferences
    // https://docs.osano.com/developer-documentation-consent-javascript-api#osano-cm-consent-saved
    window.Osano?.cm?.addEventListener(
      'osano-cm-consent-saved',
      (change: ConsentObject) => {
        switch (true) {
          case change.ANALYTICS === 'ACCEPT':
            awsRum?.allowCookies(true);
            break;
          case change.ANALYTICS === 'DENY':
            awsRum?.allowCookies(false);
            break;
        }
      },
    );
  };

  if (isAuthInProgress) {
    return (
      <Fade in={isAuthInProgress}>
        <Center h="30vh" color="white">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="grey.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      </Fade>
    );
  }
  return (
    <ApolloProvider client={getGraphqlClient(authToken ?? '')}>
      <div className="max-w-screen-4xl min-h-screen mx-auto text-md bg-gray-50 flex flex-col">
        <NavBar />
        <ErrorBoundary awsRum={awsRum}>
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <HomePage />
                </RequireAuth>
              }
            />
            <Route
              path="/onboarding/*"
              element={
                <RequireAuth>
                  <OnboardingForms />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </ErrorBoundary>
        <Footer />
      </div>
    </ApolloProvider>
  );
};
