import { Center, Fade, Spinner } from '@chakra-ui/react';
import React from 'react';

import { useAuth } from '../context/auth';
import { UnAuthorisedPage } from '../views/UnauthorisedPage';

type Props = {
  children: JSX.Element;
};
function RequireAuth({ children }: Props): JSX.Element {
  const { authToken, loading } = useAuth();
  if (loading) {
    return (
      <Fade in={loading}>
        <Center h="30vh" color="white">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="grey.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      </Fade>
    );
  }

  return authToken ? children : <UnAuthorisedPage />;
}

export default RequireAuth;
