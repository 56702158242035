import { useEffect, useState } from 'react';

export type ApiResponse<T> = {
  status: number;
  statusText: string;
  data?: T;
  error?: Error;
  loading: boolean;
};

export const useApiGet = (url: string) => {
  const [status, setStatus] = useState<number>(0);
  const [statusText, setStatusText] = useState<string>('');
  const [data, setData] = useState<unknown>();
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState<boolean>(false);

  const getAPIData = async () => {
    setLoading(true);
    try {
      const apiResponse = await fetch(url);
      const json = await apiResponse.json();
      setStatus(apiResponse.status);
      setStatusText(apiResponse.statusText);
      setData(json);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAPIData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { status, statusText, data, error, loading };
};

export function useApiPost<T = unknown>(url: string, postData: unknown): ApiResponse<T> {
  const [status, setStatus] = useState<number>(0);
  const [statusText, setStatusText] = useState<string>('');
  const [data, setData] = useState<T>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(false);

  const postAPIData = async (postData: unknown) => {
    setLoading(true);
    try {
      const requestOptions: RequestInit = {
        mode: 'no-cors',
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      };

      const apiResponse = await fetch(url, requestOptions);
      const json = (await apiResponse.json()) as T;

      setStatus(apiResponse.status);
      setStatusText(apiResponse.statusText);
      setData(json);
    } catch (error) {
      setError(error as Error);
    }
    setLoading(false);
  };

  useEffect(() => {
    postAPIData(postData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { status, statusText, data, error, loading };
}
