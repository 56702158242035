// Taken from https://codepen.io/dhavalt10/pen/rGLBzB

export const stopBrowserBack = (callback?: (...args: any[]) => void) => {
  history.pushState(null, '', location.href);

  window.onpopstate = () => {
    document.body.style.opacity = '0';

    setTimeout(() => {
      history.go(1);
      callback?.();

      setTimeout(() => (document.body.style.opacity = '1'), 50);
    }, 50);
  };
};
