import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { API_NAMES } from '../utils/constants';

const API_URLS = {
  AO_API: import.meta.env.VITE_AO_API_URL as string,
  REFERRAL_API: import.meta.env.VITE_REFERRAL_API_URL as string,
};

const cache: InMemoryCache = new InMemoryCache({
  addTypename: false,
});

const getAPILink = (uri: string, token: string): HttpLink => {
  return new HttpLink({
    uri,
    headers: {
      Authorization: token,
    },
  });
};

const getConnectionLink = (token: string): ApolloLink => {
  const aoApiLink = getAPILink(API_URLS.AO_API, token);
  const referralApiLink = getAPILink(API_URLS.REFERRAL_API, token);

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    console.error(
      'Error',
      `An error occurred while calling ${
        String(operation.getContext().clientName) || API_NAMES.SERVICE_API
      }`,
    );
    // the following is the implementation from Apollo Boost
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ` +
            `${String(locations)}, Path: ${String(path)}`,
        ),
      );
    }
    if (networkError) {
      console.warn(`[Network error]: ${networkError}`);
    }
  });

  /**
   * ApolloLink.split act as a router to different data sources (graphql endpoints) based on a condition
   * For all operations, we've to pass a clientName through context and this value will be used to determine which data source the operations need to be executed against.
   * However the .split() operation on ApolloLink only works with up to two links.
   * To overcome this, it is possible to pass the ApolloLink.split with its own conditions
   * At the end this is as ugly as deeply nested ternary operators, however as of now this is the only working solutions found to work with multiple data sources
   * Read: https://www.loudnoises.us/next-js-two-apollo-clients-two-graphql-data-sources-the-easy-way/
   */
  const httpLink = ApolloLink.split(
    (operation) => {
      return operation.getContext().clientName === API_NAMES.AO_API;
    },
    aoApiLink,
    ApolloLink.split(
      (operation) => operation.getContext().clientName === API_NAMES.REFERRAL_API,
      referralApiLink,
      aoApiLink,
    ),
  );

  // combine all links to one link
  return ApolloLink.from([errorLink, httpLink]);
};

export const getGraphqlClient = (token: string) => {
  const link: ApolloLink = getConnectionLink(token);
  return new ApolloClient({
    link,
    cache,
    // this sets the default value for the error policy for all mutations
    // "all" lets you handle the error gracefully instead of throwing an error
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
};
