import 'react-popper-tooltip/dist/styles.css';

import { ExclamationCircleIcon } from '@heroicons/react/solid';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

type Props = {
  content: string;
};
export const ToolTip = ({ content }: Props): JSX.Element => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      offset: [0, 10],
      interactive: true,
      delayHide: 250,
    });

  return (
    <div>
      <div ref={setTriggerRef} className="inline-block">
        <ExclamationCircleIcon className="h-5 w-5 text-blue-500 cursor-pointer" />
      </div>
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          <div className="p-2">
            <div className="max-w-xl text-sm text-gray-500">
              <Markdown
                options={{
                  overrides: {
                    a: {
                      component: 'a',
                      props: {
                        className:
                          'font-medium underline text-blue-700 hover:text-blue-600',
                        rel: 'noopener noreferrer',
                        target: '_blank',
                      },
                    },
                  },
                }}>
                {content}
              </Markdown>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
