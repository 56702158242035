import { AwsRum, AwsRumConfig } from 'aws-rum-web';

// List of ignored stacks and errors
const IGNORED_STACKS_REGEX = /googletagmanager|gtm/;
const IGNORED_ERRORS_REGEX = /mapCountryCodes|getRadioValue|getCheckboxValues|jquery/i;

let awsRum: AwsRum | undefined;
/**
 * Instantiate the CloudWatch RUM web client and begin monitoring the application.
 * @returns AWS RUM service instance
 */
export function AwsRumService(): AwsRum | undefined {
  if (!awsRum) {
    try {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        guestRoleArn: import.meta.env.VITE_RUM_GUEST_ROLE_ARN,
        identityPoolId: import.meta.env.VITE_RUM_POOL_ID,
        endpoint: 'https://dataplane.rum.eu-central-1.amazonaws.com',
        telemetries: [
          'performance',
          [
            'errors',
            {
              ignore: (errorEvent: { message: string; stack: string }) => {
                return (
                  errorEvent &&
                  (IGNORED_STACKS_REGEX.test(errorEvent.stack) ||
                    IGNORED_ERRORS_REGEX.test(errorEvent.message))
                );
              },
            },
          ],
          ['http', { addXRayTraceIdHeader: true }],
        ],
        allowCookies: false, // cookies are initially disabled unless user consents
        enableXRay: true,
      };

      const APPLICATION_ID = import.meta.env.VITE_RUM_APP_ID;
      const APPLICATION_VERSION = '1.0.0';
      const APPLICATION_REGION = 'eu-central-1';

      awsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config,
      );

      return awsRum;
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }

  return awsRum;
}
