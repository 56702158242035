import React from 'react';

type Props = {
  header: string;
  description: string;
};

export const FormInfo = ({ header, description }: Props): JSX.Element => (
  <div className="flex flex-col justify-between">
    <div className="px-4 sm:px-0">
      <h3 className="text-xl font-medium leading-6 text-gray-900">{header}</h3>
      <p className="mt-1 text-gray-900">{description}</p>
    </div>
  </div>
);
