import 'react-datepicker/dist/react-datepicker.css';

import { Input } from '@chakra-ui/react';
import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldProps } from '../../utils/constants/types';
import FormField from './FormField';

type Props = FieldProps & { name: string } & Omit<ReactDatePickerProps, 'onChange'>;

export const DatePicker = ({ id, name, dateFormat = 'dd.MM.yyyy', ...props }: Props) => {
  const { control } = useFormContext();
  return (
    <FormField {...{ id, ...props }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const selectedDate = field.value ? new Date(field.value) : null;
          return (
            <ReactDatePicker
              {...props}
              dateFormat={dateFormat}
              selected={selectedDate}
              onChange={(date) => {
                if (!date) {
                  field.onChange('');
                  return;
                }

                const offset = date.getTimezoneOffset();
                date = new Date(date.getTime() - offset * 60 * 1000);
                field.onChange(date?.toISOString());
              }}
              customInput={<Input id={id} />}
            />
          );
        }}
      />
    </FormField>
  );
};

export default DatePicker;
