interface Options {
  label: string;
  value: string;
}

const Collator = new Intl.Collator('en', {
  ignorePunctuation: true,
  sensitivity: 'base',
  numeric: true,
});

export const sortAlpha = (prev: Options, next: Options) => {
  return (
    (/^\d/.test(prev.label) ? 1 : 0) - (/^\d/.test(next.label) ? 1 : 0) ||
    Collator.compare(prev.label, next.label)
  );
};
