import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useSessionMetadata from '../../../hooks/sessionMetadata';
import { ConfirmationDialog } from '../../confirmation/ConfirmationDialog';

export const BackToDashboard = (): JSX.Element => {
  const { t } = useTranslation(['backToDashboard']);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { trackSessionCanceled } = useSessionMetadata();

  /**
   * Handle confirmation action by navigating user back to the MDB
   */
  const onConfirmHandler = () => {
    window.location.href = import.meta.env.VITE_MDB_URL as string;
    trackSessionCanceled();
  };

  return (
    <div className="mx-auto">
      <div className="flex flex-col items-center text-center mt-8 mx-3">
        <a
          onClick={() => setShowConfirmationDialog(true)}
          className="text-xl font-semibold text-blue-600 mb-2 cursor-pointer">
          {t('backToDashboard:back')}
        </a>
        <p className="text-sm">{t('backToDashboard:description')}</p>
        <ConfirmationDialog
          confirmBtnText={t('backToDashboard:confirmDialog.confirmBtn')}
          cancelBtnText={t('backToDashboard:confirmDialog.cancelBtn')}
          dialogHeader={t('backToDashboard:confirmDialog.header')}
          dialogText={t('backToDashboard:confirmDialog.body')}
          onConfirm={onConfirmHandler}
          onCancel={() => setShowConfirmationDialog(false)}
          isOpen={showConfirmationDialog}
        />
      </div>
    </div>
  );
};
