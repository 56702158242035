import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
} from '@chakra-ui/react';
import React, { RefObject, useRef } from 'react';

import { Button } from '../Button';

interface Props {
  isOpen: boolean;
  dialogHeader: string;
  dialogText: string;
  confirmBtnText: string;
  cancelBtnText: string;
  onConfirm: () => void;
  onCancel: () => void;
}

/**
 * Renders a customizable confirmation dialog
 */
export const ConfirmationDialog = ({
  confirmBtnText,
  cancelBtnText,
  dialogHeader,
  dialogText,
  onConfirm,
  isOpen,
  onCancel,
}: Props): JSX.Element => {
  const closeRef = useRef() as RefObject<HTMLButtonElement>;

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      isOpen={isOpen}
      leastDestructiveRef={closeRef}
      onClose={onCancel}>
      <AlertDialogOverlay />

      <AlertDialogContent
        height={{ base: '17rem', md: '15rem' }}
        pos={{ base: 'absolute', md: 'relative' }}
        bottom={{ base: '0', md: 'initial' }}
        mb={{ base: '0', md: 'initial' }}
        m={{ base: 'initial', md: 'auto' }}>
        <AlertDialogHeader fontSize="xl" fontWeight="bold">
          {dialogHeader}
        </AlertDialogHeader>
        <AlertDialogCloseButton ref={closeRef} />

        <AlertDialogBody>{dialogText}</AlertDialogBody>

        <AlertDialogFooter>
          <Button className="mr-2" secondary label={cancelBtnText} onClick={onCancel}>
            {cancelBtnText}
          </Button>
          <Button
            className="bg-red-500 hover:bg-red-700"
            label={confirmBtnText}
            onClick={onConfirm}>
            {confirmBtnText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
