import { CheckIcon } from '@heroicons/react/solid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { classNames } from '../../../utils/helpers/classNames';

interface FormStep {
  id: string;
  name: string;
  label: string;
  slug: string;
  href: string;
  active?: boolean;
  completed?: boolean;
  disabled?: boolean;
}

const formSteps: FormStep[] = [
  {
    id: '01',
    name: 'personalForm',
    label: 'Personal Data',
    slug: 'personal_data',
    href: '#',
    active: false,
    completed: false,
  },
  {
    id: '02',
    name: 'liabilitiesForm',
    label: 'VAT Data & Liabilities',
    slug: 'vat_data_liabilities',
    href: '#',
    active: false,
    completed: false,
  },
  {
    id: '03',
    name: 'vatSpecificsForm',
    label: 'VAT Specifics',
    slug: 'vat_specifics',
    href: '#',
    active: false,
    completed: false,
  },
  {
    id: '04',
    name: 'connectedSystemsForm',
    label: 'Connected Systems',
    slug: 'connected_systems',
    href: '#',
    active: false,
    completed: false,
  },
];

export const Steps = (): JSX.Element => {
  const { t } = useTranslation(['others']);
  const { pathname } = useLocation();

  const steps = formSteps.map((step) => {
    step.active = pathname.includes(step.slug);
    step.completed = false;

    switch (step.name) {
      case 'personalForm':
        step.completed = !step.active;
        step.href = '#';
        step.label = t('others:navbar.step1.label');
        break;
      case 'liabilitiesForm':
        step.label = t('others:navbar.step2.label');
        if (formSteps[0].completed) {
          step.href = 'vat_data_liabilities';
        }
        step.completed = !step.active && formSteps[0].completed;
        step.href = '#';

        break;
      case 'vatSpecificsForm':
        step.label = t('others:navbar.step3.label');
        if (formSteps[1].completed) {
          step.href = 'vat_specifics';
        }
        step.completed = !step.active && formSteps[1].completed;
        step.href = '#';

        break;
      case 'connectedSystemsForm':
        step.label = t('others:navbar.step4.label');
        if (formSteps[2].completed) {
          step.href = 'connected_systems';
        }
        step.completed = !step.active && formSteps[2].completed;
        step.href = '#';
        break;
    }

    return step;
  });

  return (
    <nav aria-label="Progress">
      <ol className="bg-white border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex-1 md:flex">
            {step.completed ? (
              <NavLink to={step.href} className="flex items-center w-full">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full">
                    <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </span>
                  <span
                    className={classNames(
                      'ml-4 text-sm font-medium',
                      step.active ? 'text-blue-600' : 'text-gray-900',
                    )}>
                    {step.label}
                  </span>
                </span>
              </NavLink>
            ) : step.active ? (
              <NavLink
                to={step.href}
                className="px-6 py-4 flex items-center text-sm font-medium"
                aria-current="step">
                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-blue-600 rounded-full">
                  <span className="text-blue-600">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-blue-600">
                  {step.label}
                </span>
              </NavLink>
            ) : (
              <NavLink to={step.href} className="flex items-center" aria-current="step">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                    <span className="text-gray-500">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500">
                    {step.label}
                  </span>
                </span>
              </NavLink>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="hidden md:block absolute top-0 right-0 h-full w-5"
                  aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none">
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};
