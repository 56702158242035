// Return all the translations for the options in object
import { TFunction } from 'react-i18next';

import { GetClientv2Query } from '../../graphql/generated/graphql';
import { emailRegex, URLRegex } from '../constants/regex';
import {
  euCountryType,
  LiabilitiesFormState,
  OnboardingFormState,
} from '../constants/types';
import { sortAlpha } from './sort';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPersonalFormOptions = (t: TFunction<('personal' | 'others')[]>) => {
  const euCountries = t('others:countries', {
    returnObjects: true,
  }) as Record<euCountryType, string>;

  const yesNoOptions = t('others:yesNoOptions', {
    returnObjects: true,
  }).map((option) => ({
    label: option.label,
    value: String(option.value),
  }));

  // List of countries to keep on top of the options list
  const topCountriesArray = [
    { label: euCountries.DE, value: 'DE' },
    { label: euCountries.AT, value: 'AT' },
    { label: euCountries.FR, value: 'FR' },
  ];
  const TOP_COUNTRIES = topCountriesArray.map((el) => el.value);

  const countryOptions = Object.entries(euCountries)
    .map(([value, label]) => ({
      label,
      value,
    }))
    .filter((el) => !TOP_COUNTRIES.includes(el.value))
    .sort((prev, next) => sortAlpha(prev, next));

  countryOptions.unshift(...topCountriesArray);

  return {
    countryOptions,
    euCountries,
    yesNoOptions,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getConnectedSystemsFormOptions = (t: TFunction<'systems'[]>) => {
  const referralOptions = t('systems:referral.options', {
    returnObjects: true,
  });

  return {
    referralOptions,
  };
};

/**
 * helper for yup transform function
 * this function is used to allow the field to accept an empty string (making the field optional)
 * then only validate when the field is not empty
 */
export const emptyStringToNull = (
  value: unknown,
  originalValue: unknown,
): null | unknown => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
};

export const getFormState = (
  initialState: OnboardingFormState,
  formData?: GetClientv2Query,
): OnboardingFormState => {
  const getClient = formData?.getClientv2;

  if (!getClient) {
    return initialState;
  }

  const personal = {
    ...initialState.personalForm.personal,
    ...getClient.personal,
  };

  const address = {
    ...initialState.personalForm.address,
    ...getClient.address,
  };

  let retroFilings = initialState.liabilitiesForm.retroFilings;
  if (getClient.retroFilings && getClient.retroFilings?.length > 0) {
    retroFilings = getClient.retroFilings;
  }

  const liabilitiesForm: LiabilitiesFormState = {
    liabilities: {
      ...initialState.liabilitiesForm.liabilities,
      ...getClient.liabilities,
      vatIds:
        getClient.vatIds?.map((vatId) => {
          return {
            ...vatId,
            validity: 'UNKNOWN',
          };
        }) || [],
    },
    retroFilings,
  };

  const vatSpecificsForm = {
    ...initialState.vatSpecificsForm,
    ...getClient.vatSpecifics,
  };

  const connectedSystemsForm = {
    ...initialState.connectedSystemsForm,
    ...getClient.connectedSystems,
  };

  return {
    personalForm: { personal, address },
    liabilitiesForm,
    vatSpecificsForm,
    connectedSystemsForm,
  };
};

/**
 * Helper const to add 1 year in the date
 * @returns {date} returns the date with addition of 1 year
 */
export const addQuarterYear = new Date(
  new Date().setFullYear(new Date().getFullYear() + 1),
);

/**
 * This function validates the input that must not contain the email
 * and URL
 * @param {string| undefined} val
 * @returns {true} true: when input does not contain URL and email
 * @returns {false} false: when input contain URL and email
 */

export const validateInput = (val: string | undefined) => {
  if (val && (val.match(emailRegex) || val.match(URLRegex))) return false;
  return true;
};
