import { Select as SelectBox, SelectProps } from '@chakra-ui/react';
import React from 'react';

import { FieldProps } from '../../utils/constants/types';
import FormField from './FormField';

type Option = {
  label: string;
  value: number | string;
  disabled?: boolean;
};
type Props = SelectProps & FieldProps & { options: Option[] };

export const Select = React.forwardRef<HTMLSelectElement, Props>(
  ({ id, ...props }, ref) => (
    <FormField {...{ id, ...props }}>
      <SelectBox ref={ref} {...props} id={id}>
        {props.options.map(({ label, value }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </SelectBox>
    </FormField>
  ),
);

export default Select;
